import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextRegular } from "../../../base/BaseText";

import { handleWhyDeviceNotEligible, handleBackToMainMenu } from "../state/operators/deviceConfirmationOperators";
import { ReactComponent as DeviceNotEligibleIcon } from "../../../images/icon-device-not-eligible.svg";

/**
 * 
 * Default type is `DeviceNotEligible`
 * Depending on type text can be altered
 */
const DeviceNotEligible = ({ type = "DeviceNotEligible", showWhyNotEligible = true }) => {
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleMoveToSmartSupport = () => {
    setDisabled(true);
    window.open(process.env.REACT_APP_SMART_SUPPORT_LINK);
  };


  const handleWhyNotEligible = () => {
    setSelected("WHY_NOT_ELIGIBLE");
    setDisabled(true);
    const text = t('DeviceNotEligible.WhyNotEligible');
    dispatch(handleWhyDeviceNotEligible(text));
  }

  const handleBackMenuClick = () => {
    setDisabled(true);
    setSelected("BACK_TO_MAIN_MENU");
    dispatch(handleBackToMainMenu(t("WhyDeviceNotEligible.BackToMainMenu")))
  }

  const WhyNotEligibleClasses = classNames("cursor-pointer text-app-secondary", {
    "pointer-events-none": disabled,
    "font-bold": selected === "WHY_NOT_ELIGIBLE",
  });

  const backToMainMenuClasses = classNames("cursor-pointer text-text-button text-default", {
    "pointer-events-none": disabled,
    "font-bold": selected === "BACK_TO_MAIN_MENU",
  });

  return (
    <BaseCard className="space-y-5">
      <DeviceNotEligibleIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey={`${type}.NotEligible`}>
          <TextRegular>
            Sorry, your device is not eligible for Screen Repair. You may consider making a Service Request under
            SmartSupport.
          </TextRegular>
        </Trans>
      </BaseLabel>
      {showWhyNotEligible && (
        <BaseLabel className={WhyNotEligibleClasses} onClick={handleWhyNotEligible}>
          <Trans i18nKey="DeviceNotEligible.WhyNotEligible" />
        </BaseLabel>
      )}
      <BaseButton
        text={t("DeviceNotEligible.MoveToBtn")}
        onClick={handleMoveToSmartSupport}
        disabled={disabled}
        clicked={disabled}
        className="mx-auto w-fit"
      />
      <BaseLabel className={backToMainMenuClasses} onClick={handleBackMenuClick}>
        <Trans i18nKey="DeviceNotEligible.BackToMainMenu" />
      </BaseLabel>
    </BaseCard>
  );
};

export default DeviceNotEligible;
