import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  resetReduxStore,
  toggleFlowWindowVisibility,
} from "../../actions/flowWindow";
import Flow from "../../container/Flow";

import starhubLogo from "../../images/starhub-logo.svg";
import chatIcon from "../../images/icon-ask-us.svg";

import Footer from "./Footer";
import HomeBody from "./HomeBody";
import { showMaintenanceText } from "../../helpers/dateUtils";

const Home = () => {
  const dispatch = useDispatch();
  const showFlowWindow = useSelector(
    (state) => state.journeyMessages.showFlowWindow
  );

  const onToggleFlowWindow = () => {
    dispatch(toggleFlowWindowVisibility(!showFlowWindow));
    dispatch(resetReduxStore());
  };

  return (
    <Fragment>
      <div className="m1repair__home">
        <header className="box-border flex bg-app-primary p-2">
          <div className="w-4/5 mx-auto">
            <img src={starhubLogo} alt="Starhub Logo" />
          </div>
        </header>
        {showMaintenanceText() && (
            <div>
            <marquee className="pt-2 font-bold text-3xl text-green-500">
              Dear customers, we will be performing our scheduled maintenance
              from 23rd May 2021, 10pm to 24th May, 6am SGT. During this time,
              our online service request and enquiry support services will not
              be available. You may contact us via screenrepair_starhub@asurion.com and we
              will reach out to you within the next 24 hours.
            </marquee>
          </div>
        )}
        <div
          className={classNames({
            "hidden lg:block xl:block": showFlowWindow,
          })}
        >
          <HomeBody onGetStarted={onToggleFlowWindow} />
          <Footer />
        </div>
      </div>

      {showFlowWindow ? (
        <Flow onToggleFlowWindow={onToggleFlowWindow} />
      ) : (
        <div
          className="fixed right-0 bottom-0 cursor-pointer mr-8 mb-8 z-20"
          onClick={onToggleFlowWindow}
        >
          <img src={chatIcon} alt="Click to chat"></img>
        </div>
      )}
    </Fragment>
  );
};

export default Home;
