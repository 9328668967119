import { callAssetsApi } from "../reducers/cowrapperReducer";
import { callCreateGeneralEnquiryApi } from "../modules/RepairClaim/state/reducers/generalEnquiryReducer";
import { callGetEnrolledServiceFeeApi } from "../modules/RepairClaim/state/reducers/paymentReducer";
import { callInquiryApi } from "../reducers/serviceRequestReducer";

export const getMakeModelList = (clientId, makeId = "") => ({
  type: callAssetsApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/corewrapper/v1/assets",
    method: "POST",
    body: {
      GetAssetsMakeModelRequest: {
        ClientId: clientId,
        makeId: makeId,
      },
    },
  },
});

export const createGeneralEnquiry = (
  carrier,
  country,
  language,
  originalQuestion
) => ({
  type: callCreateGeneralEnquiryApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/corewrapper/v1/generalinquiry",
    method: "POST",
    body: {
      GeneralInquiryRequest: {
        Carrier: carrier,
        Country: country,
        Language: language,
        OriginalQuestion: originalQuestion,
        InquiryDate: new Date(),
      },
    },
  },
});

export const getEnrolledServiceFee = (clientId, channelId, clientName, interactionLineId, mdn, cacheId) => ({
  type: callGetEnrolledServiceFeeApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/corewrapper/v1/servicefee",
    method: "POST",
    body: {
      GetServiceFeeRequest: {
        ClientId: clientId,
        ClientChannelId: channelId,
        ClientName: clientName,
        InteractionLineId: interactionLineId,
        MobileDeviceNumber: mdn,
        SessionId: cacheId,
      },
    },
  },
});

export const createInquiry = ({
  firstName,
  lastName,
  mdn,
  clientId,
  clientChannelId,
  customerContact,
  customerCaseNumber,
  selectedInquiryType,
  selectedSubInquiryType,
  CallDriverCategory,
  inquiryDesc,
  files,
  UnnecessaryInquiry = true,
  resolveStatus = true,
  priority = "Low",
  representativeDetails,
  Remarks,
  sendEmail,
  isTLCUpdateRequest,
  updatedDeliverySchedule,
  updateDeliveryAddress
}) => ({
  type: callInquiryApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/corewrapper/v1/inquiry/create",
    method: "POST",
    body: {
      CreateInquiryParams: {
        FirstName: firstName,
        LastName: lastName || "",
        SelectedInquiryType: selectedInquiryType,
        ClientId: clientId,
        ClientChannelId: clientChannelId,
        ClientName: "Starhub",
        CustomerCaseNumber: customerCaseNumber,
        InquirySubType: selectedSubInquiryType,
        CallDriverCategory: CallDriverCategory,
        CallDriverSubCategory: CallDriverCategory,
        MobileDeviceNumber: customerContact,
        ContactNumber: mdn || customerContact,
        InquiryDescription: inquiryDesc,
        Note: inquiryDesc,
        SendEmail: sendEmail,
        ResolveStatus: resolveStatus,
        UnnecessaryInquiry: UnnecessaryInquiry,
        isTLCUpdateRequest: isTLCUpdateRequest,
        Priority: priority,
        Files: files ? files : [],
        representativeDetails: representativeDetails,
        Remarks: Remarks,
        UpdateDeliverySchedule: updatedDeliverySchedule,
        UpdateDeliveryAddress: updateDeliveryAddress
      },
    },
  },
})