import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import BaseCard from "../base/BaseCard/BaseCard"
import BaseIcon from "../base/BaseIcon/BaseIcon";
import BaseLabel from "../base/BaseLabel";

const IncorrectScreen = ({
  className,
  name,
  icon,
  children,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <BaseCard
      className={classNames("flex flex-row", ...extraClasses)}
      {...restProps}
    >
      <BaseIcon className="flex-grow-0 self-center w-21r" icon={icon} name={name} />
      <BaseLabel className="text-left flex-grow text-1.3">{children}</BaseLabel>
    </BaseCard>
  );
};

IncorrectScreen.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default IncorrectScreen;
