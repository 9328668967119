import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import { TextMedium, TextRegular } from "../../../base/BaseText";
import BaseInformationBox from "../../../base/BaseInformationBox";

import { ReactComponent as CalendarCheckedIcon } from "../../../images/icon-calendar-selected.svg";
import { changeDateTimeSchedule } from "../state/operators/scheduleOperator";
import { getDeliveryDates } from "../../../helpers/dateUtils";
import { isEmpty } from "../../../helpers/formatUtils";

const ChangeDateTime = ({ isPickup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let dateOptions = [];
  const [slotOptions, setSlotOptions] = useState([]);
  const which = isPickup ? "Pick-up" : "Return";

  const [disabled, setDisabled] = useState(false);
  const [date, setDate] = useState("");
  const [slot, setSlot] = useState("");

  const pickupOptions = useSelector(
    (state) => state.claim.schedule.pickupOptions
  );
  const pickupDate = useSelector(
    (state) => state.claim.schedule.selectedPickupDate
  );
  const pickupSlot = useSelector(
    (state) => state.claim.schedule.selectedPickupSlot
  );
  const journeyCaseType = useSelector(
    (state) => state.journeyMessages.caseType
  );
  const isCampaignTimingEligible = useSelector(
    (state) => state.claim.schedule.isCampaignTimingEligible
  );
  const returnSLA = isCampaignTimingEligible ? "1-2 business days" : "2 business days"

  if (isPickup) {
    dateOptions = pickupOptions.map((obj) => {
      return {
        id: obj.date,
        text: new Date(obj.date).toDateString(),
        slots: obj.slots,
      };
    });
  } else {
    const deliveryOptions = isCampaignTimingEligible && journeyCaseType !== 'Battery Replacement'
    ? getDeliveryDates(pickupDate, pickupSlot, true, true) 
    : getDeliveryDates(pickupDate);

    dateOptions = deliveryOptions.map((obj) => {
      return {
        id: obj.date,
        text: new Date(obj.date).toDateString(),
        slots: obj.slots,
      };
    });
  }

  const handleDateChange = (dateStr) => {
    setSlot("");
    setDate(dateStr);
    let opt = dateOptions.find((o) => o.id === dateStr);
    setSlotOptions(opt.slots);
  };

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(changeDateTimeSchedule(date, slot, isPickup));
  };

  const cancelChangingDate = () => {
    dispatch(changeDateTimeSchedule(null, null, isPickup));
  };

  const shouldntSubmit = disabled || isEmpty(date) || isEmpty(slot);

  return (
    <BaseCard>
      {/* <BaseIcon icon={CalendarCheckedIcon} name="Calendar" center /> */}
      <CalendarCheckedIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey="ChangeDateTime.Title">
          <TextMedium>Select New {{ which }} Date and Time</TextMedium>
        </Trans>
      </BaseLabel>
      <BaseDropdown
        label={
          isPickup
            ? t("ChangeDateTime.Input.PickupDate")
            : t("ChangeDateTime.Input.DeliveryDate")
        }
        value={date}
        onChange={handleDateChange}
        options={dateOptions}
        disabled={disabled}
      />
      <BaseDropdown
        label={
          isPickup
            ? t("ChangeDateTime.Input.PickupSlot")
            : t("ChangeDateTime.Input.DeliverySlot")
        }
        value={slot}
        onChange={setSlot}
        options={slotOptions}
        disabled={disabled || isEmpty(slotOptions)}
        pureArray={true}
      />
      <BaseInformationBox className="text-default leading-7">
        <Trans i18nKey="ChangeDateTime.Information">
          <TextRegular>
            Please note that the Repair service will be completed in{" "}
            <TextMedium>{{returnSLA}}</TextMedium> from the date the device is
            picked up.
          </TextRegular>
        </Trans>
      </BaseInformationBox>
      <BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("SubmitButton")}
          onClick={handleSubmit}
          disabled={shouldntSubmit}
          clicked={disabled}
        />
        <BaseButton
          text={t("CancelButton")}
          onClick={cancelChangingDate}
          disabled={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ChangeDateTime.propTypes = {
  isPickup: PropTypes.bool,
};

ChangeDateTime.defaultProps = {
  isPickup: true,
};

export default ChangeDateTime;
