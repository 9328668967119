import { useState, useEffect } from "react";

import { IN_WARRANTY_SUPPORT } from "../../../helpers/constants";
import UpArrowIcon from "../../../images/UpArrow.svg";
import DownArrowIcon from "../../../images/DownArrow.svg";
import { TextMedium, TextRegular } from "../../../base/BaseText";

const InWarrantySupport = () => {
  const [optionData, setOptionsData] = useState({});

  useEffect(() => {
    const result = Object.keys(IN_WARRANTY_SUPPORT).reduce((acc, key, idx) => {
      acc[key] = idx === 0;
      return acc;
    }, {});

    setOptionsData(result);
  }, []);

  const isSelected = (criteria) => optionData[criteria];

  const handleOptionClick = (option) => {
    if (isSelected(option)) {
      const result = Object.assign({}, optionData, { [option]: false });
      setOptionsData(result);
      return;
    }

    const updatedOptionData = Object.keys(IN_WARRANTY_SUPPORT).reduce((acc, key) => {
      acc[key] = key === option;
      return acc;
    }, {});
    setOptionsData(updatedOptionData);
  };

  const { IWS_SCREEN_REPAIR, IWS_BATTERY_REPLACEMENT, IWS_DEVICE_SUITABILITY } = optionData;

  return (
    <div className="bg-white max-h-50r md:max-h-65r overflow-y-auto">
      <div className="px-0 pb-0 md:px-12 md:pb-12">
        <div className="text-4xl text-center">In-Warranty Support</div>
        <div className="mt-8 text-2xl pop-up-modal-headers">
          <TextRegular>
            Your device will come with free in-warranty support after the screen repair or battery replacement service
            is completed. The terms for the in-warranty support are detailed below.
          </TextRegular>
        </div>
        <div className="mt-8">
          <div>
            <div>
              <div
                className={`flex justify-between items-center px-8 py-6 cursor-pointer border rounded-xl ${
                  IWS_SCREEN_REPAIR ? "border-app-primary" : "border-srgray-senary"
                }`}
                onClick={() => handleOptionClick("IWS_SCREEN_REPAIR")}
              >
                <div
                  className={`w-280px sm:w-30r lg:w-auto text-3xl text-srgray-base ${
                    IWS_SCREEN_REPAIR ? "font-Lato-Medium" : "font-Lato-Regular"
                  }`}
                >
                  {IN_WARRANTY_SUPPORT.IWS_SCREEN_REPAIR}
                </div>
                <div>
                  <img src={IWS_SCREEN_REPAIR ? UpArrowIcon : DownArrowIcon} alt="screen-repair-expand-collapse" />
                </div>
              </div>
              {IWS_SCREEN_REPAIR && (
                <div className="p-6 text-srgray-base text-2xl">
                  <div>
                    <TextMedium>Screen Repair Service</TextMedium>
                  </div>
                  <div className="mt-8">
                    <TextRegular>
                      12-month warranty against manufacturer defects and malfunctions starting from the date the mobile
                      device is returned to you. Do note that manufacturing malfunctions or defects does not include
                      battery degradation.
                    </TextRegular>
                  </div>
                </div>
              )}
            </div>
            {/* IWS_BATTERY_REPLACEMENT */}
            <div>
              <div
                className={`flex justify-between items-center px-8 py-6 cursor-pointer mt-6 border rounded-xl ${
                  IWS_BATTERY_REPLACEMENT ? "border-app-primary" : "border-srgray-senary"
                }`}
                onClick={() => handleOptionClick("IWS_BATTERY_REPLACEMENT")}
              >
                <div
                  className={`w-280px sm:w-30r lg:w-auto text-3xl text-srgray-base ${
                    IWS_BATTERY_REPLACEMENT ? "font-Lato-Medium" : "font-Lato-Regular"
                  }`}
                >
                  {IN_WARRANTY_SUPPORT.IWS_BATTERY_REPLACEMENT}
                </div>
                <div>
                  <img
                    src={IWS_BATTERY_REPLACEMENT ? UpArrowIcon : DownArrowIcon}
                    alt="battery-replace-expand-collapse"
                  />
                </div>
              </div>
              {IWS_BATTERY_REPLACEMENT && (
                <div className="p-6 text-srgray-base text-2xl">
                  <div>
                    <TextMedium>Battery Replacement Service</TextMedium>
                  </div>
                  <div className="mt-8">
                    <TextRegular>
                      3-month warranty on the battery, starting from the date the mobile device is returned to you.
                    </TextRegular>
                  </div>
                </div>
              )}
            </div>

            <div>
              <div
                className={`flex justify-between items-center px-8 py-6 cursor-pointer mt-6 border rounded-xl ${
                  IWS_DEVICE_SUITABILITY ? "border-app-primary" : "border-srgray-senary"
                }`}
                onClick={() => handleOptionClick("IWS_DEVICE_SUITABILITY")}
              >
                <div
                  className={`w-280px sm:w-30r lg:w-auto text-3xl text-srgray-base ${
                    IWS_DEVICE_SUITABILITY ? "font-Lato-Medium" : "font-Lato-Regular"
                  }`}
                >
                  {IN_WARRANTY_SUPPORT.IWS_DEVICE_SUITABILITY}
                </div>
                <div>
                  <img src={IWS_DEVICE_SUITABILITY ? UpArrowIcon : DownArrowIcon} alt="both-expand-close" />
                </div>
              </div>
              {IWS_DEVICE_SUITABILITY && (
                <div className="p-6 text-srgray-base text-2xl">
                  <div>Here’s a quick checklist to gauge is your device is suitable for an in-warranty request.</div>
                  <div className="mt-6">
                    <p className="font-Lato-Medium">Screen repair</p>
                    <ul className="pl-10 list-outside list-disc">
                      <li>My device is not exposed to liquid damage/corrosion.</li>
                      <li>My device has no physical damage (cracked screen/back, bent body, etc.).</li>
                      <li>
                        My device issue is not related to a battery problem (poor battery life, battery degradation,
                        etc.).
                      </li>
                    </ul>
                  </div>
                  <div className="mt-6">
                    <p className="font-Lato-Medium">Battery replacement</p>
                    <ul className="pl-10 list-outside list-disc">
                      <li>My device is not exposed to liquid damage/corrosion.</li>
                      <li>My device has no physical damage (cracked screen/back, bent body, etc.).</li>
                      <li>My device has a battery-related issue.</li>
                    </ul>
                  </div>
                  <div className="mt-6">
                    <p className="font-Lato-Medium">Screen repair and battery replacement</p>
                    <ul className="pl-10 list-outside list-disc">
                      <li>My device is not exposed to liquid damage/corrosion.</li>
                      <li>My device has no physical damage (cracked screen/back, bent body, etc.).</li>
                      <li>My device has a battery related issue.</li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InWarrantySupport;
