import axios from "axios";
import Config from "../modules/Appsync/Config";

export function getTwilioConfig() {
  let data;
  let carrier;
  let config;

  return new Promise((resolve, reject) => {
    getCachedStaticFile(
      Config.TaskService.baseUrlSEA,
      "client/twiliopercarrier.json",
      false
    )
      .then((result) => {
        data = JSON.parse(result);
        carrier = findObjectWithKey("client", "starhubsur", data.configs);
        config = findObjectWithKey(
          "env",
          Config.WPAPIServer.env.toLowerCase(),
          carrier.envs
        );
        resolve(config);
      })
      .catch((error) => {
        resolve(config);
      });
  });
}

export function getCachedStaticFile(url, name, getCached = true) {
  return new Promise((resolve, reject) => {
    let jsonString = sessionStorage.getItem(name);

    try {
      if (jsonString === null || !getCached) {
        //try SEA URL
        getStaticFile(`${url}/v1/static/file?name=${name}`)
          .then((result) => {
            jsonString = JSON.stringify(result.data);
            // cache in sessionStorage
            sessionStorage.setItem(name, jsonString);
            // resolve from static file (S3)
            resolve(jsonString);
          })
          .catch((error) => {
            // force resolve
            resolve(null);
          });
      } else {
        // resolve from sessionStorage
        resolve(jsonString);
      }
    } catch (error) {
      //
    }
  });
}

export async function getStaticFile(url) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      headers: {},
      method: "GET",
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findObjectWithKey(key, value, objects, startsWith) {
  let object = null;
  let keyValue;

  if (objects != null) {
    for (let s = 0; s < objects.length; s++) {
      object = objects[s];
      keyValue = object[key];
      if (startsWith) {
        if (keyValue.indexOf(value) > -1) {
          break;
        }
      } else {
        if (keyValue != null && keyValue == value) {
          break;
        }
      }
      // reset to null
      object = null;
    }
  }
  return object;
}
