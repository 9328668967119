import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SelectCurrentDevice from "./SelectCurrentDevice";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { isRegisteredDeviceConfirmed } from "../state/operators/deviceConfirmationOperators";
import moment from "moment";

const DisplayDeviceList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const handleSelect = (selectedAssetData) => {
    setDisabled(true);
    dispatch(isRegisteredDeviceConfirmed(true, selectedAssetData, true));
  };

  const enrolledAssetData = useSelector(
    (state) => state.validation.verification.enrolledAsset
  );

  const replacedAssetData = useSelector(
    (state) => state.validation.verification.replacedAsset
  );

  const compareEnrolledAndReplacedAssets = () => {
    if (replacedAssetData.StartDate &&
      moment(replacedAssetData.StartDate).isAfter(moment(enrolledAssetData.StartDate))) {
      return "REPLACED"
    }

    return "ENROLLED"
  }

  const currentDevice = compareEnrolledAndReplacedAssets();

  return (
    <BaseCard className="max-w-xl">
      <BaseLabel>{t("SelectRegisteredDevice.Title")}</BaseLabel>
      <div className="flex flex-col md:flex-row overflow-x-auto ">
        {/* {enrolledAssetData &&
          !enrolledAssetData.EndDate &&
          <SelectCurrentDevice
            deviceDetails={enrolledAssetData}
            data={t("SelectRegisteredDevice.SelectYourDevice.1")}
            disabled={disabled}
            handleSelect={handleSelect}
          />
        } */}
        {/* <SelectCurrentDevice
          deviceDetails={replacedAssetData}
          data={t("SelectRegisteredDevice.SelectYourDevice.2")}
          disabled={disabled}
          handleSelect={handleSelect}
        /> */}
        {currentDevice === "ENROLLED" &&
          <SelectCurrentDevice
            deviceDetails={enrolledAssetData}
            data={t("SelectRegisteredDevice.SelectYourDevice.1")}
            disabled={disabled}
            handleSelect={handleSelect}
          />
        }
        {currentDevice === "REPLACED" &&
          <SelectCurrentDevice
            deviceDetails={replacedAssetData}
            data={t("SelectRegisteredDevice.SelectYourDevice.2")}
            disabled={disabled}
            handleSelect={handleSelect}
          />
        }
      </div>
    </BaseCard>
  );
};

export default DisplayDeviceList;
