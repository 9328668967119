import {
  agreementsApi,
  setMdnData,
  setNamesData,
  setNricData,
  verifyAgreementApi,
} from "./actions";
import { addJourneyMessages, sleep } from "../../../helpers/addJourneyMessages";
import createMessage from "../../../helpers/createMessage";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  INCIDENT_PATH_TYPE,
} from "../../../helpers/constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
} from "../../../actions/flowWindow";
import {
  cancelServiceRequest,
  determineRequest,
} from "../../../actions/serviceRequestApi";
import { getMaskedData, isEmpty } from "../../../helpers/formatUtils";
import updateVisitor from "../../Appsync/updateVisitor";
import { udpateChatInputVisibility } from "../../Appsync/state/operators";
import { setChatReason } from "../../Appsync/state/reducers";

//async action creator or thunk

export const validateMdn = (mdn) => async (dispatch, getStore) => {
  // Before API Call
  const {
    CacheId,
    UserName = "User ",
  } = getStore().session.sessionData.Configurations;
  const { agreement } = getStore().validation;
  const agreementFailedAttempt = agreement
    ? agreement.AgreementFailedAttempt
    : 0;

  await dispatch(setMdnData(mdn));
  await dispatch(
    updateApiProgress(API_PROGRESS.ASK_MDN, 40, COMPONENTS.ASK_MDN, 0)
  );

  // API Call
  const response = await dispatch(
    agreementsApi({
      cacheId: CacheId,
      mdn,
      customerName: UserName,
      AgreementFailedAttempt: agreementFailedAttempt,
    })
  ).catch((err) => dispatch(serviceUnavailable()));

  if (isEmpty(response)) {
    return;
  }

  // After API Call

  const {
    Agreement: { AgreementFound, AgreementFailedAttempt },
  } = response;

  const progressMessage = AgreementFound ? API_PROGRESS.ASK_MDN_SUCCESS : API_PROGRESS.ASK_MDN_FAILURE;
  await dispatch(
    updateApiProgress(progressMessage, 100, COMPONENTS.ASK_MDN, 40)
  );
  await sleep(1000);
  await dispatch(clearApiProgress());

  if (AgreementFound) {
    dispatch(addJourneyMessages([createMessage("TEXT", "user", mdn)]));
    dispatch(
      updateVisitor({ mdn, lastActivity: ACTIVITY.ASK_MDN })
    ).catch((err) => dispatch(serviceUnavailable()));

    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.ASK_NAME.title,
        PROGRESS_DATA.ASK_NAME.count,
        initial
      )
    );
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", { key: "SystemMessage.AskFullName" }),
        createMessage("VALIDATION", "system", { showComponent: "AskName" }),
      ])
    );
  } else {
    // dispatch(
    //   addJourneyMessages([
    //     createMessage("VALIDATION", "system", {
    //       showComponent: "IncorrectMdn",
    //     }),
    //   ])
    // );
    // if (AgreementFailedAttempt < 3) {
    //   dispatch(
    //     addJourneyMessages([
    //       createMessage("VALIDATION", "system", {
    //         showComponent: "ReVerifyMdn",
    //       }),
    //     ])
    //   );
    // }

    if (AgreementFailedAttempt >= 3) {
      dispatch(addJourneyMessages([
        createMessage("TEXT", "system", { key: "IncorrectMessage.MdnFailedAttemptExceeded" }),
        createMessage("GENERAL_ENQUIRY", "system", { showComponent: "IsExistingCustomer", }),
      ]));
    }
  }
};

export const validateName = (firstName, lastName) => async (
  dispatch,
  getStore
) => {
  // Before API Call
  const customerName = `${firstName} ${lastName}`;
  const { CacheId } = getStore().session.sessionData.Configurations;
  const {
    agreement: { NameFailedAttempt: nameFailedAttempt },
    inputData: { mdn },
  } = getStore().validation;
  dispatch(addJourneyMessages([createMessage("TEXT", "user", customerName)]));
  await dispatch(setNamesData(firstName, lastName));
  await dispatch(
    updateApiProgress(API_PROGRESS.ASK_NAME, 40, COMPONENTS.ASK_NAME)
  );

  // API Call
  const response = await dispatch(
    agreementsApi({
      mdn,
      cacheId: CacheId,
      firstName,
      lastName,
      customerName,
      NameFailedAttempt: nameFailedAttempt,
    })
  ).catch((err) => dispatch(serviceUnavailable()));

  if (isEmpty(response)) {
    return;
  }

  // After API Call
  await dispatch(
    updateApiProgress(
      API_PROGRESS.ASK_NAME_SUCCESS,
      100,
      COMPONENTS.ASK_NAME,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
  const {
    Agreement: { NameVerified, NameFailedAttempt },
  } = response;
  if (NameVerified) {
    dispatch(
      updateVisitor({ userName: customerName, lastActivity: ACTIVITY.ASK_NAME })
    );
    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.ASK_NRIC.title,
        PROGRESS_DATA.ASK_NRIC.count,
        initial
      )
    );
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.AskForNric",
          values: { firstName },
        }),
        createMessage("VALIDATION", "system", { showComponent: "AskNric" }),
      ])
    );
  } else {
    dispatch(
      addJourneyMessages([
        createMessage("VALIDATION", "system", {
          showComponent: "IncorrectName",
        }),
      ])
    );
    if (NameFailedAttempt < 4) {
      dispatch(
        addJourneyMessages([
          createMessage("VALIDATION", "system", { showComponent: "AskName" }),
        ])
      );
    } else {
      await dispatch(setChatReason("Invalid Name"));
      dispatch(udpateChatInputVisibility(true));
    }
  }
};

export const validateNric = (nricCode, isAgentVerified = false) => async (
  dispatch,
  getStore
) => {
  // Before API Call
  const { CacheId } = getStore().session.sessionData.Configurations;
  const {
    verification,
    inputData: { mdn, firstName },
  } = getStore().validation;
  let { VerificationFailedAttempt: verificationFailedAttempt } = !isEmpty(
    verification
  )
    ? verification
    : {};
  if (nricCode !== "AGENT_VERIFIED") {
    const maskedNric = getMaskedData(nricCode, "NRIC");
    dispatch(addJourneyMessages([createMessage("TEXT", "user", maskedNric)]));
  }
  await dispatch(setNricData(nricCode));
  await dispatch(
    updateApiProgress(API_PROGRESS.ASK_NRIC, 40, COMPONENTS.ASK_NRIC)
  );

  // check agent verified
  // verificationFailedAttempt = isAgentVerified ? 0 : verificationFailedAttempt;

  // API Call
  const response = await dispatch(
    verifyAgreementApi({
      mdn,
      cacheId: CacheId,
      verificationFailedAttempt,
      nricCode,
      isAgentVerified,
    })
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(response)) return;

  // After API Call
  const {
    VerificationOutcome: {
      VerifyCustomerResults: { VerificationOutcome },
      VerificationFailedAttempt,
    },
  } = response;
  if (VerificationOutcome === "Verified") {
    dispatch(updateVisitor({ lastActivity: ACTIVITY.ASK_NRIC }));
    let determineResponse = await dispatch(
      determineRequest(CacheId)
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(determineResponse)) return;

    const { Type: incidentPathType } = determineResponse.IncidentPath;

    // if (incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST) {
    //   // cancel SR
    //   const { CacheId } = getStore().session.sessionData.Configurations;
    //   const {
    //     ServiceRequestId,
    //     CustomerCaseId,
    //   } = getStore().serviceRequest.serviceRequestDetails;
    //   const note = "Request Cancelled by User";

    //   let cancelSRResponse = await dispatch(
    //     cancelServiceRequest(
    //       CacheId,
    //       ServiceRequestId,
    //       note,
    //       "",
    //       CustomerCaseId,
    //       "",
    //       ""
    //     )
    //   ).catch((err) => dispatch(serviceUnavailable()));
    //   if (isEmpty(cancelSRResponse)) return;
    //   // call determine incident again
    //   determineResponse = await dispatch(
    //     determineRequest(CacheId)
    //   ).catch((err) => dispatch(serviceUnavailable()));
    //   if (isEmpty(determineResponse)) return;
    // } else 
    if (incidentPathType === INCIDENT_PATH_TYPE.WAIT_FOR_HOLD) {
      // device hold
      dispatch(
        addJourneyMessages([
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "deviceHold",
          }),
        ])
      );
    }
    await dispatch(
      updateApiProgress(
        API_PROGRESS.ASK_NRIC_SUCCESS,
        100,
        COMPONENTS.ASK_NRIC,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());
    if (
      determineResponse.IncidentPath.Type === INCIDENT_PATH_TYPE.WAIT_FOR_HOLD
    ) {
      return;
    }
    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.ENQUIRY_OPTIONS.title,
        PROGRESS_DATA.ENQUIRY_OPTIONS.count,
        initial
      )
    );
    dispatch(
      addJourneyMessages([
        // createMessage("TEXT", "system", {
        //   key: "SystemMessage.HowCanWeAssist",
        //   values: { firstName },
        // }),
        createMessage("ENQUIRY_OPTIONS", "system", {}),
      ])
    );
  } else {
    await dispatch(
      updateApiProgress(
        API_PROGRESS.ASK_NRIC_SUCCESS,
        100,
        COMPONENTS.ASK_NRIC,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());
    dispatch(
      addJourneyMessages([
        createMessage("VALIDATION", "system", {
          showComponent: "IncorrectNric",
        }),
      ])
    );
    if (VerificationFailedAttempt < 2) {
      dispatch(
        addJourneyMessages([
          createMessage("VALIDATION", "system", { showComponent: "AskNric" }),
        ])
      );
    } else {
      await dispatch(setChatReason("Invalid Nric"));
      dispatch(udpateChatInputVisibility(true));
    }
  }
};
