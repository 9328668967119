import { EnumEnquiryModules } from "../../../../components/EnquiryOptions/Constants";
import {
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  ONLINE_JOURNEY_STATUS_LIST,
} from "../../../../helpers/constants";
import {
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY,
  INCIDENT_BATTERY_AND_SCREEN,
} from "../../../../components/EnquiryOptions/Constants";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import {
  sleep,
  addJourneyMessages,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { submitOrder } from "../actions/paymentApi";
import updateVisitor from "../../../Appsync/updateVisitor";
import { isEmpty } from "../../../../helpers/formatUtils";
import { initializePickupSchedule } from "./scheduleOperator";
import moment from "moment";
import {
  setIsAfterCutoffError,
} from "../reducers/scheduleReducer";

export const confirmRequestDetailsSubmission = () => async (
  dispatch,
  getStore
) => {
  const enquireyOption = getStore().journeyMessages.enquiryOption;
  switch (enquireyOption) {
    case EnumEnquiryModules.CancelRequest:
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", "Cancel my Repair Request"),
          createMessage("CANCEL_REPAIR", "system", {
            showComponent: "ConfirmRequestCancel",
          }),
        ])
      );
      break;
    case EnumEnquiryModules.NewRequest:
    case EnumEnquiryModules.NewRequestBatteryReplacement:
    case EnumEnquiryModules.NewRequestScreenAndBattery:
    case EnumEnquiryModules.InWarrantyRequest:
    case EnumEnquiryModules.ResumeRequest:
      const { caseType } = getStore().journeyMessages;
      const { isCampaignTimingEligible, selectedPickupDate, selectedPickupSlot } = getStore().claim.schedule;
      const is1DayTAT = true;//extend 1 day TAT after campaign

      if (isCampaignTimingEligible) {
        if (caseType === INCIDENT_SCREEN_REPAIR ||
          caseType === INCIDENT_SCREEN_REPAIR_WARRANTY ||
          caseType === INCIDENT_BATTERY_AND_SCREEN) {

          const format = 'hh:mm:ss';
          const TimeBefore12NN = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_1_BEFORE || '11:45', format);
          // const TimeBefore12NN = moment('00:00', format); // TEST ONLY
          const TimeBefore2PM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_2_END || '13:45', format);
          const nowtime = moment();

          if (
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '12pm - 2pm' &&
            nowtime.isAfter(TimeBefore12NN)) ||
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '2pm - 6pm' &&
            nowtime.isAfter(TimeBefore2PM))
            ) {
              await sleep(1000);

              dispatch(
                addJourneyMessages([
                  createMessage("TEXT","system","Sorry. The selected pick-up schedule is no longer valid. Please choose a new pick-up schedule.")
                ])
              );

              await sleep(1000);

              dispatch(setIsAfterCutoffError(true));
              dispatch(initializePickupSchedule());

              let selectedComponent = "confirmPickupAddress";
              let selectedModule = "PICKUP_AND_DELIVERY";
              
              dispatch(
                addJourneyMessages([
                  createMessage(selectedModule, "system", { showComponent: selectedComponent }),
                ])
              );

              break;
          } else {
            dispatch(setIsAfterCutoffError(false));
          }
        }
      } else {
        if (caseType === INCIDENT_SCREEN_REPAIR ||
          caseType === INCIDENT_SCREEN_REPAIR_WARRANTY || 
          caseType === INCIDENT_BATTERY_AND_SCREEN) {

          /*isBefore7_45AM
          isBefore1_45PM
          return ["9am - 12pm", "12pm - 4pm"]*/

          const format = 'hh:mm:ss';
          const TimeBefore8AM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_1_BEFORE || '07:45', format);
          const TimeBefore2PM = moment(process.env.REACT_APP_SUR_CAMPAIGN_CUTOFF_TIME_2_END || '13:45', format);
          const nowtime = moment();

          if (
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '9am - 12pm' &&
            nowtime.isAfter(TimeBefore8AM)) ||
            (moment(selectedPickupDate).isSame(nowtime, 'day') &&
            selectedPickupSlot === '12pm - 4pm' &&
            nowtime.isAfter(TimeBefore2PM))
            ) {
              await sleep(1000);

              dispatch(
                addJourneyMessages([
                  createMessage("TEXT","system","Sorry. The selected pick-up schedule is no longer valid. Please choose a new pick-up schedule.")
                ])
              );

              await sleep(1000);

              dispatch(setIsAfterCutoffError(true));
              dispatch(initializePickupSchedule());

              let selectedComponent = "confirmPickupAddress";
              let selectedModule = "PICKUP_AND_DELIVERY";
              
              dispatch(
                addJourneyMessages([
                  createMessage(selectedModule, "system", { showComponent: selectedComponent }),
                ])
              );

              break;
          } else {
            dispatch(setIsAfterCutoffError(false));
          }
        }
      }

      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", "Submit Request"),
          createMessage("TEXT", "system", {
            key: "SystemMessage.RequestSubmitted",
          }),
        ])
      );
      await dispatch(
        updateApiProgress(
          API_PROGRESS.REQUEST_SUBMIT,
          40,
          COMPONENTS.CONFIRM_REQUEST_DETAILS,
          0
        )
      );

      // Call to Submit Request API
      const { CacheId } = getStore().session.sessionData.Configurations;
      // const submitOrderResponse = await dispatch(
      //   submitOrder(CacheId)
      // ).catch((err) => dispatch(serviceUnavailable()));
      // if (isEmpty(submitOrderResponse)) return;
      let submitOrderResponse = null;
      let retrycount = 0;
      while (retrycount < 2) {
        submitOrderResponse = await dispatch(submitOrder(CacheId))
          .catch((err) => { return null }
          );

        if (submitOrderResponse === null) {
          retrycount++;
        } else {
          retrycount = 2;
        }
      }
      // not allowing to go ahead if response is empty
      if (isEmpty(submitOrderResponse)) {
        dispatch(serviceUnavailable())
        return;
      }

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.SUBMIT_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.COMPLETED,
        })
      );

      await dispatch(
        updateApiProgress(
          API_PROGRESS.REQUEST_SUBMIT_SUCCESS,
          100,
          COMPONENTS.CONFIRM_REQUEST_DETAILS,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.FEEDBACK.title,
          PROGRESS_DATA.FEEDBACK.count,
          initial
        )
      );
      dispatch(
        addJourneyMessages([
          createMessage("IMPORTANT_NOTE", "system", {}),
          createMessage("TEXT", "system", {
            key: "SystemMessage.ThankYourForUsingService",
          }),
          createMessage("TEXT", "system", {
            key: "SystemMessage.InviteToSurvey",
          }),
          createMessage("NPS", "system", {
            showComponent: "RequestForSurvey",
          }),
        ])
      );
      break;
    default:
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "user", "Request details confirmed"),
        ])
      );
      break;
  }
};

export const cancelRepairDetailsConfirmation = () => (dispatch) => {
  dispatch(
    addJourneyMessages([createMessage("TEXT", "user", "Cancel Repair Request")])
  );
  dispatch(confirmToCancelRequest("", "Cancel-Confirmation page", true));
};
