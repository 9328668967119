import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "./../images/icon-close-dark.svg";
import { TextRegular } from "../base/BaseText";
import BaseProgressBar from "../base/BaseProgressBar/BaseProgressBar";
import { useSelector } from "react-redux";
import { COMPONENTS } from "../helpers/constants";
import { getFormmatedDate, getFormatedTime } from "../helpers/dateUtils";
import { isEmpty } from "../helpers/formatUtils";

const HeaderProgress = ({ text, percentage, initial = 0 }) => {
  return (
    <>
      {percentage > 0 && percentage < 100 ? (
        <div className="flex flex-row w-full">
          <div className="text-4xl mr-8 relative font-bold">
            {percentage}
            <span className="font-Lato-Medium text-default absolute top-0"><span>%</span></span>
          </div>
          <div className="pt-2 w-full pr-4">
            <div className="text-xl text-srgray-base font-bold">
              <TextRegular>{text}</TextRegular>
            </div>
            <BaseProgressBar
              className="h-2 mt-2 mb-0 w-full"
              percentage={percentage}
              initial={initial}
              type={COMPONENTS.FLOW_PROGRESS}
              uniqueId={COMPONENTS.FLOW_PROGRESS}
            />
          </div>
        </div>
      ) : percentage === 100 ? (
        "Your request is submitted"
      ) : (
        <div className="p-2 text-default font-bold">
          <TextRegular>Screen Repair and Battery Replacement Portal</TextRegular>
        </div>
      )}
    </>
  );
};

const FlowHeader = ({ onClose }) => {
  const [currentDateStr, setCurrentDateStr] = useState();
  const [caseNo, setCaseNo] = useState();

  useEffect(() => {
    const newDate = new Date();
    setCurrentDateStr(newDate.toString());
  }, []);

  const caseNumberD = useSelector((state) =>
    state.serviceRequest.determineIncidentDetails
      ? state.serviceRequest.determineIncidentDetails.ServiceRequestNumber
      : ""
  );

  const caseNumberR = useSelector((state) =>
    state.serviceRequest.serviceRequestDetails
      ? state.serviceRequest.serviceRequestDetails.ServiceRequestNumber
      : ""
  );

  useEffect(() => {
    if (!isEmpty(caseNumberR)) {
      setCaseNo(caseNumberR);
    } else if (!isEmpty(caseNumberD)) {
      setCaseNo(caseNumberD);
    }
  }, [caseNumberD, caseNumberR]);

  const flowProgressData = useSelector(
    (state) => state.journeyMessages.flowProgress
  );

  return (
    <>
      <div className="app__flow-header">
        <div className="app__flow-content">
          <HeaderProgress {...flowProgressData} />
        </div>
        <div className="app__flow-exit-button" onClick={onClose}>
          <img src={CloseIcon} alt="Close" />
        </div>
      </div>
      <div className="bg-app-light py-2 px-8 flex justify-between text-1.3">
        <div>
          {currentDateStr &&
            `${getFormmatedDate(
              currentDateStr,
              "dd MMM yyyy"
            )} ${getFormatedTime(currentDateStr)}`}
        </div>
        {!isEmpty(caseNo) && <div>SR No. {caseNo}</div>}
      </div>
    </>
  );
};

FlowHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FlowHeader;
