import { useState, useEffect } from "react";

import { DEVICE_ELIGIBILITY_CRITERIA, SAMSUNG_APP_DOWNLOAD_LINK } from "../../../helpers/constants";
import UpArrowIcon from "../../../images/UpArrow.svg";
import DownArrowIcon from "../../../images/DownArrow.svg";
import BatteryCheckImage from "../../../images/battery-check.png";
import IOSBatteryCheckImage from "../../../images/ios-battery-health-check.png";


const DeviceEligibilityCriteria = ({ title }) => {
  const [optionData, setOptionsData] = useState({});

  useEffect(() => {
    const result = Object.keys(DEVICE_ELIGIBILITY_CRITERIA).reduce((acc, key, idx) => {
      acc[key] = idx === 0;
      return acc;
    }, {});

    setOptionsData(result);
  }, []);

  const isSelected = (criteria) => optionData[criteria];

  const handleOptionClick = (option) => {
    if (isSelected(option)) {
      const result = Object.assign({}, optionData, { [option]: false });
      setOptionsData(result);
      return;
    }

    const updatedOptionData = Object.keys(DEVICE_ELIGIBILITY_CRITERIA).reduce((acc, key) => {
      acc[key] = key === option;
      return acc;
    }, {});
    setOptionsData(updatedOptionData);
  };

  const handleDownloadClick = () => {
    window.open(SAMSUNG_APP_DOWNLOAD_LINK, "_blank");
  }

  const { SCREEN_REPAIR, BATTERY_REPLACEMENT, BATTERY_HEALTH_GUIDE } = optionData;

  return (
    <div className="bg-white h-50r md:h-65r overflow-y-auto">
      <div className="px-0 pb-0 md:px-12 md:pb-12">
        <div className="text-4xl text-center pop-up-modal-headers">{title}</div>
        <div className="mt-8">
          <div>
            <div>
              <div
                className={`flex justify-between items-center px-8 py-6 cursor-pointer border rounded-xl ${
                  SCREEN_REPAIR ? "border-app-primary" : "border-srgray-senary"
                }`}
                onClick={() => handleOptionClick("SCREEN_REPAIR")}
              >
                <div
                  className={`w-280px sm:w-30r lg:w-auto text-2 text-srgray-base ${SCREEN_REPAIR ? "font-Lato-Medium" : "font-Lato-Regular"}`}
                >
                  {DEVICE_ELIGIBILITY_CRITERIA.SCREEN_REPAIR}
                </div>
                <div>
                  <img src={SCREEN_REPAIR ? UpArrowIcon : DownArrowIcon} alt="screen-repair-expand-collapse" />
                </div>
              </div>
              {SCREEN_REPAIR && (
                <div className="p-6 text-srgray-base text-1.6">
                  <div>
                    <p>
                      All new or existing SmartSupport, and SmartSupport Lite subscribers will be able to access Screen Repair service. You will be advised on your accessibility when you submit a Screen Repair request.
                    </p>
                  </div>
                  <div>
                    <div className="mt-6 font-Lato-Medium">Device condition checklist:</div>
                    <div className="pl-10">
                      <ul className="list-outside list-disc">
                        <li>My device is able to power on.</li>
                        <li>My device camera is in good working condition.</li>
                        <li>My device has not been exposed to any liquid damage/corrosion.</li>
                        <li>Any screen protector on my device has been removed.</li>
                        <li>
                          Other than the damage on the front screen, my device does not have any other damage such as:
                        </li>
                        <ul className="pl-8 list-outside list-disc">
                          <li>Bending of device, or</li>
                          <li>Cracks or damage on the back of device.</li>
                        </ul>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* BATTERY_REPLACEMENT */}
            <div>
              <div
                className={`flex justify-between items-center px-8 py-6 cursor-pointer mt-6 border rounded-xl ${
                  BATTERY_REPLACEMENT ? "border-app-primary" : "border-srgray-senary"
                }`}
                onClick={() => handleOptionClick("BATTERY_REPLACEMENT")}
              >
                <div
                  className={`w-280px sm:w-30r lg:w-auto text-2 text-srgray-base ${
                    BATTERY_REPLACEMENT ? "font-Lato-Medium" : "font-Lato-Regular"
                  }`}
                >
                  {DEVICE_ELIGIBILITY_CRITERIA.BATTERY_REPLACEMENT}
                </div>
                <div>
                  <img src={BATTERY_REPLACEMENT ? UpArrowIcon : DownArrowIcon} alt="battery-replace-expand-collapse" />
                </div>
              </div>
              {BATTERY_REPLACEMENT && (
                <div className="p-6 text-srgray-base text-1.6">
                  <div>
                    <p>SmartSupport subscribers who enrolled 
                      <span className="font-Lato-Medium"> on or before 30 March 2022, </span>
                      and SmartSupport Lite subscribers are not eligible for battery replacement. 
                    </p>
                  </div>
                  <div className="mt-6">
                    <p>SmartSupport subscribers who enrolled 
                      <span className="font-Lato-Medium"> from 31 March 2022 onwards </span>
                      can access Battery Replacement service. 
                    </p>
                  </div>
                  <div className="mt-6">
                    <p>You will be advised on your accessibility when you submit a Battery Replacement request.</p>
                  </div>
                  <div className="mt-6">
                    <p>SmartSupport Lite subscribers are<span className="font-Lato-Medium"> not eligible </span>
                     for Battery Replacement service.
                    </p>
                  </div>
                  <div className="mt-6">
                    <p className="font-Lato-Medium">Device condition checklist: </p>
                    <ul className="pl-10 list-outside list-disc">
                      <li>My device is able to power on.</li>
                      <li>My device camera is in good working condition.</li>
                      <li>My device has not been exposed to any liquid damage/corrosion.</li>
                      <li>My device does not have any other damage such as:</li>
                      <ul className="pl-8 list-outside list-disc">
                        <li>Bending of device, or</li>
                        <li>Cracks or damage on the back of device.</li>
                        <li>Cracks or damage on the front of device (unless the request is for both battery replacement and screen repair).</li>
                      </ul>
                      <li>My device is not swollen or bloated.</li>
                      <li>The device battery health indicator for:</li>
                      <ul className="pl-8 list-outside list-disc">
                        <li>Apple device: Less than 80%</li>
                        <li>Samsung device: Bad/Weak</li>
                        <li>Others: not required</li>
                      </ul>
                    </ul>
                    <p className="mt-8">
                      Jump to:{" "}
                      <span
                        className="cursor-pointer text-app-secondary"
                        onClick={() => handleOptionClick("BATTERY_HEALTH_GUIDE")}
                      >
                        Battery Health Check Guide
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div>
              <div
                className={`flex justify-between items-center px-8 py-6 cursor-pointer mt-6 border rounded-xl ${
                  BATTERY_HEALTH_GUIDE ? "border-app-primary" : "border-srgray-senary"
                }`}
                onClick={() => handleOptionClick("BATTERY_HEALTH_GUIDE")}
              >
                <div
                  className={`w-280px sm:w-30r lg:w-auto text-2 text-srgray-base ${
                    BATTERY_HEALTH_GUIDE ? "font-Lato-Medium" : "font-Lato-Regular"
                  }`}
                >
                  {DEVICE_ELIGIBILITY_CRITERIA.BATTERY_HEALTH_GUIDE}
                </div>
                <div>
                  <img src={BATTERY_HEALTH_GUIDE ? UpArrowIcon : DownArrowIcon} alt="both-expand-close" />
                </div>
              </div>
              {BATTERY_HEALTH_GUIDE && (
                <div className="p-6 text-srgray-base text-1.6">
                  <div>
                    Before requesting for a Battery Replacement Service, you will need to check if your Apple iPhone or
                    Samsung phone meets the battery health conditions stated above in our <span className="text-app-secondary">Device Eligibility Criteria</span>.
                  </div>
									<div className="mt-6">Read the guides below for step-by-step instructions on how to check your device battery health.</div>
									<div className="mt-6">
										<div className="pt-8">
											<p className="font-Lato-Medium text-2">Check Battery Health on Apple Devices</p>
											<ol className="mt-6 pl-10 list-outside list-decimal">
												<li>Go to the <span className="font-Lato-Medium">Settings {">"} Battery.</span></li>
												<li>Select <span className="font-Lato-Medium">Battery Health</span>.</li>
												<li>Check the <span className="font-Lato-Medium">Maximum Capacity </span>indicator.</li>
												<li>You may proceed to submit a Battery Replacement request if the <span className="font-Lato-Medium">capacity less than 80%.</span></li>
											</ol>
											<div className="mt-6 flex justify-center">
												<img src={IOSBatteryCheckImage} alt="ios-battery-check-img"/>
											</div>
											<p className="font-Lato-Medium text-2">Check Battery Status on Samsung Devices</p>
											<ol className="mt-6 pl-10 list-outside list-decimal">
												<li>Download the <span className="text-app-secondary cursor-pointer" onClick={() => handleDownloadClick()}>Samsung Members app</span> onto your device.</li>
												<li>Launch the Samsung Members app and tap <span className="font-Lato-Medium">Get Help</span>.</li>
												<li>Under Diagnostics, tap <span className="font-Lato-Medium">Start</span>.</li>
												<li>Create a Samsung account if you don’t have one, or log in using your Google account or existing Samsung account.</li>
												<li>Once you’re logged in, <span className="font-Lato-Medium">go to Get Help {">"} Diagnostics</span> and tap <span className="font-Lato-Medium">Start</span>.</li>
												<li>Select <span className="font-Lato-Medium">Battery status</span>. This will start the scan.</li>
												<li>Check your <span className="font-Lato-Medium">Battery status</span>.</li>
												<li>You may proceed to submit a Battery Replacement request if the status is <span className="font-Lato-Medium">Bad/Weak</span>.</li>
											</ol>
											<div className="mt-6 flex justify-center">
												<img src={BatteryCheckImage} alt="battery-check-img"/>
											</div>
										</div>
									</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceEligibilityCriteria;
