import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseSeparatedInput from "../../../base/BaseSeparatedInput/BaseSeparatedInput";
import BaseLink from "../../../base/BaseLink/BaseLink";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseInformationBox from "../../../base/BaseInformationBox";
import { TextMedium, TextRegular } from "../../../base/BaseText";

import {
  verifyDeviceImei,
  initiateChatForImei,
  handleBackToMainMenu,
} from "../state/operators/deviceConfirmationOperators";
import { isEmpty } from "../../../helpers/formatUtils";
import generateUniqueId from "../../../helpers/generateUniqueId";
import { REGEX } from "../../../helpers//constants";
import { ReactComponent as ImeiIcon } from "../../../images/icon-phone-imei.svg";

const VerifyImei = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [imei, setImei] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [selectedButton, setSelectedButton] = useState(null)

  const handleSubmitImei = () => {
    dispatch(verifyDeviceImei(imei));
    setDisabled(true);
    setSelectedButton("Submit");
  };

  useEffect(() => {
    setUniqueId(generateUniqueId());
  }, []);

  const handleDontKnowImei = () => {
    setDisabled(true);
    dispatch(initiateChatForImei());
  };

  const handleBackClick = () => {
    setDisabled(true);
    setSelectedButton("Back")
    dispatch(handleBackToMainMenu(t("BackButton")))
  }

  const shouldntSubmit = disabled || isEmpty(imei) || imei.length < 6;

  return (
    <BaseCard>
      {/* <BaseIcon icon={ImeiIcon} name="IMEI" center /> */}
      <ImeiIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey="VerifyImei.Title">
          <TextMedium>
            Please enter LAST 6 digits of
            <br /> your device's IMEI code
          </TextMedium>
        </Trans>
      </BaseLabel>
      <BaseSeparatedInput
        name={`ImeiVerify-${uniqueId}`}
        value={imei}
        length={6}
        onChange={setImei}
        disabled={disabled}
        onEnterPress={shouldntSubmit ? () => { } : handleSubmitImei}
        autoFocus
        regex={REGEX.Number}
      />

      <BaseInformationBox>
        <BaseLabel className="text-left m-0">
          <TextMedium>{t("VerifyImei.Information.Title")}</TextMedium>
        </BaseLabel>
        <BaseLabel className="text-left text-default m-0">
          <Trans i18nKey="VerifyImei.Information.Content" components={{ regular: <TextRegular />, medium: <TextMedium /> }} />
        </BaseLabel>
      </BaseInformationBox>
      <BaseLink
        className="text-center my-3 text-1.6 text-app-secondary"
        disabled={disabled}
        onClick={handleDontKnowImei}
      >
        <TextMedium>{t("VerifyImei.DontKnowLink")}</TextMedium>
      </BaseLink>
      <BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("SubmitButton")}
          onClick={handleSubmitImei}
          disabled={shouldntSubmit}
          clicked={selectedButton === 'Submit'}
          focused
        />
        <BaseButton
          text={t("BackButton")}
          onClick={handleBackClick}
          disabled={disabled}
          clicked={selectedButton === 'Back'}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default VerifyImei;
