import React from "react";

export const TextRegular = ({ children }) => {
  return <span className="font-Lato-Regular">{children}</span>;
};

export const TextLight = ({ children }) => {
  return <span className="font-Lato-Light">{children}</span>;
};

export const TextMedium = ({ children }) => {
  return <span className="font-Lato-Medium">{children}</span>;
};

export const TextBold = ({ children }) => {
  return <span className="font-Lato-Black">{children}</span>;
};