import DOMPurify from "dompurify";

export const toPascalCase = (text) =>
  text.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });

export const sanitize = (value) => {
  return DOMPurify.sanitize(value);
};

export const isEmpty = (input) => {
  if (typeof input === "undefined" || input === "null") {
    return true;
  }
  if (typeof input === "function") {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  if (typeof input === "string" && input.trim().length === 0) {
    return true;
  }
  return !input || Object.keys(input).length === 0;
};

export function getMaskedCCNum(str) {
  if (str !== "") {
    str = new Array(str.length - 3).join("*") + str.substr(str.length - 4, 4);
  }
  return str;
}

export function getFormattedUserName(name = "") {
  name = name ? name.replace(/\s{2,}/g, " ") : name;
  let isSpaceFound = name.indexOf(" ") > -1;
  name = isSpaceFound ? name.split(" ") : name;

  if (isSpaceFound) {
    var [firstName, ...rest] = name;
  }

  return {
    firstName: isSpaceFound ? firstName : name,
    lastName: isSpaceFound && rest ? rest.join(" ") : "",
    fullName: name.length > 0 && rest ? `${firstName} ${rest.join(" ")}` : name,
  };
}

export const getMaskedData = (code, type) => {
  let maskedCode = code;
  switch (type) {
    case "EMAIL":
      let split = code.split("@");
      const nameLength = split[0].length;
      if (nameLength > 3) {
        maskedCode =
          code.substr(0, 1) +
          new Array(nameLength - 2).fill("*").join("") +
          split[0][nameLength - 1] +
          "@" +
          split[1];
      } else if (nameLength === 3) {
        maskedCode = code.substr(0, 1) + "**";
      } else {
        maskedCode = new Array(nameLength).fill("*").join("");
      }
      break;
    case "MDN":
      maskedCode = code[0] + "**" + code.substr(3, 2) + "**" + code[7];
      break;
    case "IMEI":
      maskedCode = code[0] + "**" + code[3] + "**";
      break;
    case "NRIC":
      maskedCode = code[0] + "**" + code[3];
      break;
    default:
      break;
  }
  return maskedCode;
};
