import React from 'react';
import { HashRouter as Router, Switch, Route } from "react-router-dom";

import { Provider } from 'react-redux';
import store from './store';

import HomePage from './pages/Home/Homepage';
import WordpressContent from './pages/WordpressContent';

import TagManager from "react-gtm-module";
import Config from './modules/Appsync/Config';

const tagManagerArgs = {
  gtmId: Config.GTM.gtmId,
};

function App() {

  TagManager.initialize(tagManagerArgs);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/:content" component={WordpressContent} />
        </Switch>
      </Router>
    </Provider>
  );
}

export function getComponentForStaticHtml(Component, message) {
  return (
    <Provider store={store}>
      <Component {...message} {...message.data}/>
    </Provider>
  );
}

export default App;
