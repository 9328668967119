import React, { useState } from "react";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";

import { ReactComponent as CancelRequestIcon } from "../../../images/icon-request-cancel.svg";
import { useTranslation, Trans } from "react-i18next";
import { TextRegular } from "../../../base/BaseText";
import { useDispatch } from "react-redux";
import {
  confirmToCancelRequest,
  discardCancelRequest,
} from "../state/operators/cancelRequestOperator";

const ConfirmRequestCancel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [status, setStatus] = useState("");

  const handleSubmitCancelRequest = (status) => {
    setDisabled(true);
    setStatus(status);
    if (status === "Yes") {
      dispatch(confirmToCancelRequest(status));
    } else {
      dispatch(discardCancelRequest(status));
    }
  };

  return (
    <BaseCard>
      {/* <BaseIcon icon={CancelRequestIcon} name="Cancel Request Icon" center /> */}
      <CancelRequestIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey="ConfirmRequestCancel.Title">
          <TextRegular>
            Are you sure you want to cancel your Repair Request?
          </TextRegular>
        </Trans>
      </BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("YesButton")}
          onClick={() => handleSubmitCancelRequest("Yes")}
          disabled={disabled}
          clicked={status === "Yes"}
        />
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmitCancelRequest("No")}
          disabled={disabled}
          clicked={status === "No"}
        />

      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRequestCancel;
