import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseTable, { BaseTR, BaseTH, BaseTD } from "../../../base/BaseTable/BaseTable";

import { ReactComponent as PickUpAddressIcon } from "../../../images/icon-address.svg";
import { ReactComponent as DeliveryAddressIcon } from "../../../images/icon-tracking.svg";
import { TextMedium, TextRegular } from "../../../base/BaseText";
import { confirmationOfAddress } from "../state/operators/scheduleOperator";

const ConfirmAddress = ({ isPickup }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scheduleData = useSelector((state) => state.claim.schedule);
  const whichUpper = isPickup ? "Pick-up" : "Return";
  const which = whichUpper.toLowerCase();
  const addressIcon = isPickup ? (
    <PickUpAddressIcon className="app-BaseIcon-center" />
  ) : (
    <DeliveryAddressIcon className="app-BaseIcon-center" />
  );
  const { Address1, Address2, Address3, City, PostalCode } = isPickup
    ? scheduleData.selectedPickupAddress
    : scheduleData.selectedDeliveryAddress;
  const address = `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const handleConfirmAdress = (status) => {
    setConfirmationStatus(status);
    setDisabled(true);
    dispatch(confirmationOfAddress(status, isPickup));
  };

  return (
    <BaseCard>
      {/* <BaseIcon icon={addressIcon} name="Tracking" center /> */}
      {addressIcon}
      <BaseLabel>
        <Trans i18nKey="ConfirmAddress.Title">
          <TextMedium>
            Please confirm the <br />
            {{ which }} address of your device
          </TextMedium>
        </Trans>
      </BaseLabel>
      <BaseTable title={t(`ConfirmAddress.TableTitle.${whichUpper}`)} verticle>
        <BaseTR>
          <BaseTH>{`${whichUpper} Address`}</BaseTH>
          <BaseTD>
            {address}
          </BaseTD>
        </BaseTR>
      </BaseTable>
      <BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("ConfirmButton")}
          onClick={() => handleConfirmAdress("Confirm")}
          disabled={disabled}
          clicked={confirmationStatus === "Confirm"}
        />
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleConfirmAdress("Change")}
          disabled={disabled}
          clicked={confirmationStatus === "Change"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

ConfirmAddress.propTypes = {
  isPickup: PropTypes.bool,
};

ConfirmAddress.defaultProps = {
  isPickup: true,
};

export default ConfirmAddress;
