import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { helpNeededForInquiry } from "../state/operators/generalEnquiryOperator";
import { FAQ_LINK } from "../../../helpers/constants";

const GeneralEquiryOptions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [selection, setSelection] = useState("");

  const handleSubimt = (answer) => {
    setSelection(answer);
    dispatch(helpNeededForInquiry(answer));
    setDisabled(true);
  };
  const handleFAQClick = () => {
    window.open(FAQ_LINK, "_blank");
  };

  return (
    <BaseCard className="p-0 pb-8 relative">
      <BaseLabel
        className="font-bold text-app-secondary cursor-pointer"
        onClick={handleFAQClick}
      >
        Visit FAQ page
      </BaseLabel>
      <BaseLabel>{t("GeneralEnquiry.Options.Title")}</BaseLabel>
      <BaseButtonGroup>
        <BaseButton
          onClick={() => handleSubimt("No")}
          text={t("NoButton")}
          disabled={disabled}
          clicked={selection === "No"}
          className="px-12"
        />
        <BaseButton
          onClick={() => handleSubimt("Yes")}
          text={t("YesButton")}
          disabled={disabled}
          clicked={selection === "Yes"}
          className="px-12"
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default GeneralEquiryOptions;
