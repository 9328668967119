import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium, TextRegular } from "../../../base/BaseText";
import BaseTable, {
  BaseTR,
  BaseTH,
  BaseTD,
} from "../../../base/BaseTable/BaseTable";

import { redirectToPhoneNumber } from "../state/operators/scheduleOperator";
import { getFormmatedDate } from "../../../helpers/dateUtils";
import { ReactComponent as ScheduleIcon } from "../../../images/icon-schedule.svg";

const ConfirmDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const scheduleData = useSelector((state) => state.claim.schedule);
  const { isAfterCutoffError } = useSelector((state) => state.claim.schedule);

  const tableHeaders = [
    { key: "pickupAddress", text: "Pick-up Address" },
    { key: "returnAddress", text: "Return Address" },
    { key: "pickupDateTime", text: "Pick-up Date/Time" },
    { key: "returnDateTime", text: "Return Date/Time" },
  ];

  const getAddressText = (address) => {
    const { Address1, Address2, Address3, City, PostalCode } = address;
    return `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;
  };

  const tableData = [
    {
      pickupAddress: getAddressText(scheduleData.selectedPickupAddress),
      returnAddress: getAddressText(scheduleData.selectedDeliveryAddress),
      pickupDateTime: (
        <span>
          {`${getFormmatedDate(scheduleData.selectedPickupDate, "d MMM yyyy")},`}
          <br />
          {`${getFormmatedDate(scheduleData.selectedPickupDate, "D")}`}
          <br />
          {`${scheduleData.selectedPickupSlot}`}
        </span>
      ),
      returnDateTime: (
        <span>
          {`${getFormmatedDate(
            scheduleData.selectedDeliveryDate,
            "d MMM yyyy"
          )},`}
          <br />
          {`${getFormmatedDate(scheduleData.selectedDeliveryDate, "D")}`}
          <br />
          {`${scheduleData.selectedDeliverySlot}`}
        </span>
      ),
    },
  ];

  const [disabled, setDisabled] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState("");

  const handleConfirmDetails = (status) => {
    setConfirmationStatus(status);
    setDisabled(true);
    dispatch(redirectToPhoneNumber(status, isAfterCutoffError));
  };

  return (
    <BaseCard>
      {/* <BaseIcon icon={ScheduleIcon} name="Tracking" center /> */}
      <ScheduleIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <TextMedium>
          <Trans i18nKey="ConfirmScheduleDetails.Title">
            Please confirm the details <br />
            of pick-up and return for your device
          </Trans>
        </TextMedium>
      </BaseLabel>
      <BaseTable title={t("ConfirmScheduleDetails.Table.Title")}>
        {tableHeaders.map((header) => (
          <BaseTR key={header.key}>
            <BaseTH>{header.text}</BaseTH>
            {tableData.map((data, i) => (
              <BaseTD key={`${header.key}_${i}`}>
                <TextRegular>{data[header.key]}</TextRegular>
              </BaseTD>
            ))}
          </BaseTR>
        ))}
      </BaseTable>
      <BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("ConfirmButton")}
          onClick={() => handleConfirmDetails("Confirm")}
          disabled={disabled}
          clicked={confirmationStatus === "Confirm"}
        />
        <BaseButton
          text={t("ChangeButton")}
          onClick={() => handleConfirmDetails("Change")}
          disabled={disabled}
          clicked={confirmationStatus === "Change"}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmDetails;
