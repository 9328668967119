import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseDropdown from "../../../base/BaseDropdown/BaseDropdown";
import BaseInput from "../../../base/BaseInput/BaseInput";
import BaseTooltip from "../../../base/BaseTooltip/BaseTooltip";
import { TextMedium } from "../../../base/BaseText";

import { ReactComponent as VerifyPhoneIcon } from "../../../images/icon-choose-device.svg";
import { ReactComponent as HelpIcon } from "../../../images/icon-help.svg";
import {
  submitChosenEnrolledDevice,
  getDeviceMakeModelOptions,
} from "../state/operators/deviceConfirmationOperators";
import { isEmpty } from "../../../helpers/formatUtils";

const IMEIToolTip = () => <Trans i18nKey="ToolTip.IMEI" components={{ medium: <TextMedium /> }}/>
const Tooltip = () => <BaseTooltip text={<IMEIToolTip />}>
  <HelpIcon />
</BaseTooltip>

const ChooseEnrolledDevice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deviceData = useSelector((state) => state.claim.deviceConfirmation);
  const { makeOptions, modelOptions } = deviceData;

  const [disabled, setDisabled] = useState(false);
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [imei, setImei] = useState("");
  const [isDeviceChosen, setIsDeviceChosen] = useState("");

  const handleChosenDevice = (isChosen) => {
    let deviceData = isChosen
      ? {
          make: makeOptions.find((m) => m.Make === make),
          model: modelOptions.find((m) => m.ModelId === model),
          imei,
        }
      : {};
    setIsDeviceChosen(isChosen ? "Submit" : "Cancel");
    dispatch(submitChosenEnrolledDevice(deviceData));
    setDisabled(true);
  };

  useEffect(() => {
    dispatch(getDeviceMakeModelOptions());
  }, []);

  const handleMakeChange = (makeId) => {
    dispatch(getDeviceMakeModelOptions(makeId));
    setMake(makeId);
    setModel("");
    setImei("");
  };

  const handleModelChange = (modelId) => {
    setModel(modelId);
    setImei("");
  };

  const handleImeiChange = (value) => {
    if (!isNaN(value)) setImei(value);
  };

  const shouldntSubmit =
    disabled ||
    isEmpty(make) ||
    isEmpty(model) ||
    isEmpty(imei) ||
    imei.length !== 15;

  return (
    <BaseCard>
      {/* <BaseIcon icon={VerifyPhoneIcon} name="Choose device icon" center /> */}
      <VerifyPhoneIcon className="app-BaseIcon-center" />
      <BaseLabel className="text-1.6 font-bold leading-7">
        <Trans i18nKey="ChooseEnrolledDevice.Title" components={{ medium: <TextMedium />}} />
      </BaseLabel>
      <BaseDropdown
        label={t("ChooseEnrolledDevice.Input.SelectMake")}
        value={make}
        onChange={handleMakeChange}
        options={makeOptions}
        disabled={disabled || isEmpty(makeOptions)}
        identifier="Make"
        printer="Make"
      />
      <BaseDropdown
        label={t("ChooseEnrolledDevice.Input.SelectModel")}
        value={model}
        onChange={handleModelChange}
        options={modelOptions}
        disabled={disabled || isEmpty(modelOptions)}
        identifier="ModelId"
        printer="PopularName"
      />
      <BaseInput
        label={t("ChooseEnrolledDevice.Input.EnterImeiLabel")}
        placeholder={t("ChooseEnrolledDevice.Input.EnterImei")}
        value={imei}
        onChange={handleImeiChange}
        className="mt-0"
        maxLength={15}
        disabled={disabled || isEmpty(module)}
        medium
        Suffix={Tooltip}
      />
      <BaseButtonGroup>
        <BaseButton
          className="bg-app-nova border-app-nova"
          text={t("SubmitButton")}
          onClick={() => handleChosenDevice(true)}
          clicked={isDeviceChosen === "Submit"}
          disabled={shouldntSubmit}
        />
        <BaseButton
          text={t("CancelButton")}
          onClick={() => handleChosenDevice(false)}
          clicked={isDeviceChosen === "Cancel"}
          disabled={disabled}
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ChooseEnrolledDevice;
