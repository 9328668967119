import React from "react";
import PropTypes from "prop-types";
import Troubleshoot from "./Troubleshoot";

const View = ({ showComponent, perilType = "ScreenRepair", inWarranty = false }) => {
  return (
    <>
      {showComponent === "Troubleshoot" && <Troubleshoot perilType={perilType} inWarranty={inWarranty}/>}
      {/* {showComponent === "Troubleshoot" && <Troubleshoot perilType="InWarrantyBatteryRepair" inWarranty/>} */}
      {/* {showComponent === "Troubleshoot" && <Troubleshoot perilType="InWarrantyScreenAndBatteryRepair" inWarranty/>} */}
    </>
  );
};

View.propTypes = {
  showComponent: PropTypes.string.isRequired,
  perilType: PropTypes.string.isRequired,
  inWarranty: PropTypes.bool,
};

export default View;
