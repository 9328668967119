import { INCIDENT_SCREEN_REPAIR } from "../../../../components/EnquiryOptions/Constants";
import { getFormmatedDate, isToday } from "../../../../helpers/dateUtils";
import {
  callCreateRepairRequestApi,
  callGetShippingApi,
  callServiceOrderApi,
  callStandardizeAddressApi,
  callUpdateShippingApi,
} from "../reducers/scheduleReducer";

export const standardizeAddressApi = (
  CacheId,
  isEnrolledAddress,
  addressObj = {},
  addressRetryAttempt = false
) => ({
  type: callStandardizeAddressApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/incidentidentification/v1/standardiseaddress`,
    method: "POST",
    body: {
      QASParams: {
        SessionId: CacheId,
        AddressRetryAttempt: addressRetryAttempt,
        Address: addressObj,
        IsEnrolled: isEnrolledAddress,
      },
    },
  },
});

export const serviceOrderApi = (
  caseType,
  cacheId,
  interactionLineId,
  serviceRequestId,
  customerCaseId,
  assetCatalogId
) => {
  const fulfillmentMethodType = caseType === INCIDENT_SCREEN_REPAIR ? "SCRNREP": "BTRREP";
  return {
    type: callServiceOrderApi.toString(),
    meta: {
      async: true,
      blocking: true,
      path: `/physicalassetfulfillment/v1/serviceorder`,
      method: "POST",
      body: {
        CreateServiceOrderParameters: {
          SessionId: cacheId,
          ApprovedServiceFeeChange: false,
          InteractionLineId: interactionLineId,
          ServiceOrder: {
            FulfillmentMethodType: fulfillmentMethodType,
            RepairDescription: "Starhub Screen Repair",
            ServiceRequestId: serviceRequestId,
            CustomerCaseId: customerCaseId,
            ServiceOrderLines: {
              ServiceOrderLine: [
                {
                  ServiceOrderLineType: "PHN",
                  Quantity: 1,
                  AssetCatalogId: assetCatalogId,
                  VendorItemId: "",
                  Priority: "MNDTRY",
                  VenderItemType: "PHN",
                  VenderItemDescription: "PHONE",
                  VendorData: {
                    IsLikeForLike: true,
                    IsSimCard: false,
                    IsInKit: false,
                    IsInStock: true,
                  },
                },
              ],
            },
          },
        },
      },
    },
  }
};

export const createRepairRequestApi = (
  cacheId,
  customerCaseId,
  serviceRequestId,
  serviceOrderId
) => ({
  type: callCreateRepairRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/screenrepair/v1/repairrequest/create`,
    method: "POST",
    body: {
      CreateRepairRequestParameters: {
        SessionId: cacheId,
        CustomerCaseId: customerCaseId,
        ServiceRequestId: serviceRequestId,
        ServiceOrderId: serviceOrderId,
        RepairRequestType: "PUR",
      },
    },
  },
});

export const getShippingAddressApi = (CacheId) => ({
  type: callGetShippingApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/shippingorder/get`,
    method: "POST",
    body: {
      GetShippingOrderParameters: {
        SessionId: CacheId,
      },
    },
  },
});

export const updateShippingAddressApi = (
  cacheId,
  customerName,
  serviceOrderId,
  serviceRequestId,
  pickupDate,
  pickupSlot,
  pickupAddress,
  deliveryDate,
  deliveryAddress
) => ({
  type: callUpdateShippingApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/physicalassetfulfillment/v1/shippingorder`,
    method: "POST",
    body: {
      UpdateShippingOrderParameters: {
        EntityUpdateOptions: {
          ReturnEntity: true,
        },
        SessionId: cacheId,
        ServiceOrderId: serviceOrderId,
        ServiceRequestId: serviceRequestId,
        ShippingOrder: {
          ShippingMethodType: isToday(pickupDate)
            ? "Same Day"
            : "Next Day",
          CarrierNumber: "347",
          SignatureRequired: true,
          RequestedShipmentDate: getFormmatedDate(
            new Date(pickupDate),
            "yyyy-mm-dd",
            "-"
          ),
          ExpectedDeliveryDate: getFormmatedDate(
            new Date(pickupDate),
            "yyyy-mm-dd",
            "-"
          ),
          DeliveryWindow: pickupSlot === "12pm - 2pm" || pickupSlot === "10am - 2pm"
            ? "14H"
            : pickupSlot === "9am - 12pm"
              ? "12H"
              : pickupSlot === "2pm - 6pm" ? "18H" : "16H",
          Remark: "",
          ReceiverName: customerName,
          ReceiverAddress: {
            AddressLine1: pickupAddress.Address1,
            AddressLine2: pickupAddress.Address2,
            AddressLine3: pickupAddress.Address3,
            CityName: pickupAddress.City,
            StateProvinceCode: pickupAddress.StateProvinceCode,
            PostalCode: pickupAddress.PostalCode,
            Country: pickupAddress.Country
              ? pickupAddress.Country
              : "Singapore",
            CountryCode: pickupAddress.CountryCode,
            Standardized: pickupAddress.Standardized
              ? pickupAddress.Standardized
              : true,
            StandardizedSourceName: pickupAddress.StandardizedSourceName
              ? pickupAddress.StandardizedSourceName
              : "QAS",
            BaseAddressHeaderId: pickupAddress.BaseAddressHeaderId
              ? pickupAddress.BaseAddressHeaderId
              : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
            IsOverridden: false,
            AddressId: pickupAddress.AddressId,
          },
        },
        InboundShippingOrder: {
          ShippingMethodType: pickupSlot === "12pm - 2pm" || pickupSlot === "10am - 2pm" ? "1BD" : "2BD",
          CarrierNumber: "347",
          SignatureRequired: true,
          RequestedShipmentDate: getFormmatedDate(
            new Date(deliveryDate),
            "yyyy-mm-dd",
            "-"
          ),
          ExpectedDeliveryDate: getFormmatedDate(
            new Date(deliveryDate),
            "yyyy-mm-dd",
            "-"
          ),
          DeliveryWindow: "18H",
          Remark: "",
          ReceiverName: customerName,
          ReceiverAddress: {
            AddressLine1: deliveryAddress.Address1,
            AddressLine2: deliveryAddress.Address2,
            AddressLine3: deliveryAddress.Address3,
            CityName: deliveryAddress.City,
            StateProvinceCode: deliveryAddress.StateProvinceCode,
            PostalCode: deliveryAddress.PostalCode,
            Country: deliveryAddress.Country
              ? deliveryAddress.Country
              : "Singapore",
            CountryCode: deliveryAddress.CountryCode,
            Standardized: deliveryAddress.Standardized
              ? deliveryAddress.Standardized
              : true,
            StandardizedSourceName: deliveryAddress.StandardizedSourceName
              ? deliveryAddress.StandardizedSourceName
              : "QAS",
            BaseAddressHeaderId: deliveryAddress.BaseAddressHeaderId
              ? deliveryAddress.BaseAddressHeaderId
              : "109f4afca0ecfbf50235f0d5e890495e3d4c78abab6289782e81ea7319eb0d4fb21851a3bf6af03c6df5a08032f63649",
            IsOverridden: false,
            AddressId: deliveryAddress.AddressId,
          },
        },
      },
    },
  },
});
