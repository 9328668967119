import React from "react";
// import { ReactComponent as ThankYouImage } from "../images/image-thank-you.svg";
// import { ReactComponent as ThankYou } from "../images/thankyou.svg";
import ThankYouImg from "../images/thankyou.png";
import { ReactComponent as CloseIcon } from "../images/icon-close.svg";

const ThankYouNote = ({ onClose }) => {
  return (
    <div className="w-full h-full bg-white flex flex-col overflow-hidden relative">
      <div className="w-full h-full">
        <div className="flex justify-between w-full">
          <div className="w-px"></div>
          <div className="cursor-pointer p-6" onClick={onClose}>
            {/* <img src={CloseIcon} alt="Close" /> */}
            <CloseIcon />
          </div>
        </div>
        <div className="bottom-0 h-full z-0 mx-auto w-full">
          <div className="h-full flex flex-col-reverse bg-srgray-thankYou">
            <img
              className="h-full p-0 mx-auto"
              src={ThankYouImg}
              alt="Thank You"
            />
            {/* <ThankYou className="h-full p-0 mx-auto" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouNote;
