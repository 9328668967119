import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextLight } from "../BaseText";

const BaseCheckbox = ({
  id,
  label,
  value,
  onChange,
  className,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <div className={classNames("app-BaseCheckbox", ...extraClasses)}>
      <div>
        <input
          className="app-BaseCheckbox__input"
          {...restProps}
          type="checkbox"
          id={id}
          value={value}
          onChange={onChange}
        />
      </div>
      <label htmlFor={id} className="app-BaseCheckbox__label">
        <TextLight>{label}</TextLight>
      </label>
    </div>
  );
};

BaseCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default BaseCheckbox;
