import {
  callDetermineRequestApi,
  callCreateServiceRequestApi,
  callCancelServiceRequestApi,
  callResumeServiceRequestApi,
} from "../reducers/serviceRequestReducer";

export const determineRequest = (cacheId) => ({
  type: callDetermineRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/determine",
    method: "POST",
    body: {
      DetermineIncidentPaths: {
        SessionId: cacheId,
      },
    },
  },
});

export const createServiceRequest = (cacheId) => ({
  type: callCreateServiceRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/create",
    method: "POST",
    body: {
      CreateServiceRequestParams: {
        SessionId: cacheId,
      },
    },
  },
});

export const cancelServiceRequest = (cacheId, requestId, note, customerCaseId = "", subReason = "", serviceOrderId = "", cancelAction = "") => ({
  type: callCancelServiceRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/cancel",
    method: "POST",
    body: {
      CancelServiceRequestParams: {
        SessionId: cacheId,
        ServiceRequestId: requestId,
        NoteText: note,
        Operation: "Cancel",
        Reason: "Cancelled by user",
        RequestedBy: "Customer",
        SubReason: subReason,
        CancelEntities: "ALL",
        UpdatedBy: "Customer",
        ReturnEntity: true,
        CustomerCaseId: customerCaseId,
        ServiceOrderId: serviceOrderId,
        CancelAction: cancelAction
      },
    },
  },
});

export const resumeServiceRequest = (cacheId, requestId) => ({
  type: callResumeServiceRequestApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/servicerequest/v1/resume",
    method: "POST",
    body: {
      ResumeServiceRequestParameters: {
        SessionId: cacheId,
        ServiceRequestId: requestId,
      },
    },
  },
});
