import initializeApi from "./initializeApi";
import interactionApi from "./interactionApi";
import businessHoursApi from "./businessHoursApi";
import { addJourneyMessages } from "./../helpers/addJourneyMessages";
import createMessage from "./../helpers/createMessage";
import createVisitor from "../modules/Appsync/createVisitor";
import axios from "axios";

const fetchBetaTestingConfigFlag = async () => {
  let source = axios.CancelToken.source();

  let result = await axios.get(
    `files/beta-testing.json`,
    {
      cancelToken: source.token
    }
  ).then((resp) => {
    return resp?.data?.isBetaTestingActive || false;
  }).catch((error) => {
    console.error(error);

    if (axios.isCancel(error)) {
      console.log(`request cancelled:${error.message}`);
    } else {
      console.log("other error:" + error.message);
    }

    return false;
  });

  return result;
}

export const initProcessFlow = () => async (dispatch, getStore) => {
  // const session = getStore().session;
  // const {CacheId, UserName = "User"} = session && session.sessionData && session.sessionData.Configurations;
  //call initialize api and interaction api
  let initializeResponse = await dispatch(initializeApi());
  const { CacheId, UserName = "User" } = initializeResponse.Configurations;
  await dispatch(interactionApi(CacheId, UserName));
  await dispatch(businessHoursApi());
  await dispatch(createVisitor());
  //configure appsync and twilio and then show journey message
  const list = getStore().journeyMessages.messageList;
  // const shouldWelcomeComponentLoad =
  //   list.findIndex((m) => m.type === "WELCOME_TO_SERVICE") === -1;

  // const shouldMdnComponentLoad =
  //   list.findIndex(
  //     (m) => m.type === "VALIDATION" && m.data.showComponent === "mdn"
  //   ) === -1;

  const shouldMyAsurionToggleLoad =
    list.findIndex(
      (m) => m.type === "VALIDATION" && m.data.showComponent === "ToggleMyAsurion"
    ) === -1;

  // if (shouldWelcomeComponentLoad)
  //   dispatch(
  //     addJourneyMessages([createMessage("WELCOME_TO_SERVICE", "system")])
  //   );

  // if (shouldMdnComponentLoad) {
  //   dispatch(
  //     addJourneyMessages([
  //       createMessage("VALIDATION", "system", { showComponent: "mdn" }),
  //     ])
  //   );
  // }
  if (shouldMyAsurionToggleLoad) {
    let isBetaTestingEnabled = await fetchBetaTestingConfigFlag();

    if (isBetaTestingEnabled) {
      dispatch(
        addJourneyMessages([
          createMessage("VALIDATION", "system", { showComponent: "ToggleMyAsurion" }),
        ])
      );
    } else {
      dispatch(
        addJourneyMessages([
          createMessage("VALIDATION", "system", { showComponent: "mdn" }),
        ])
      );
    }
  }
};
