import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { initModuleTrigger } from "../../actions/initModuleTrigger";
import ENQUIRY_OPTIONS, { EnumEnquiryModules, PROGRAM_NAME, NEW_ENQUIRY_OPTIONS } from "./Constants";
import BaseLabel from "../../base/BaseLabel";
import BaseAccordian from "../../base/BaseAccordian/BaseAccordian";
import { TextLight } from "../../base/BaseText";
import { INCIDENT_PATH_TYPE } from "../../helpers/constants";
import { isEmpty } from "../../helpers/formatUtils";

const EnquiryOptions = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [options, setOptions] = useState([]);
  const srType = useSelector((state) => state.serviceRequest.type);
  const { ServiceRequestType, RepairRequest, Servicerequests = [] } = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );
  const { ClientOffer: programName } = useSelector(state => state.validation?.agreement)

  useEffect(() => {
    const isNewProgram = programName === PROGRAM_NAME;
    const inquiryOptions = ENQUIRY_OPTIONS.Options
    .map((menu, idx) => isNewProgram && idx === 0 ? { ...menu, options: [ ...menu.options, ...NEW_ENQUIRY_OPTIONS] } : menu);
    setOptions(inquiryOptions);
  }, [programName])


  const optionsToBeDisabled = () => {
    switch (srType) {
      case INCIDENT_PATH_TYPE.START_SERVICE_REQUEST:
        return [
          EnumEnquiryModules.ResumeRequest,
          EnumEnquiryModules.ChangeSchedule,
          EnumEnquiryModules.CancelRequest,
        ];
      case INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST:
        let options = [];
        if (
          RepairRequest &&
          ["CCX COMPLETED", "CCX STARTED"].includes(RepairRequest.RepairStatus)
        ) {
          options.push(
            EnumEnquiryModules.CreateNewRequest,
            EnumEnquiryModules.ResumeRequest,
            EnumEnquiryModules.CancelRequest
          );
        } else if (RepairRequest && RepairRequest.RepairStatus !== "RESERVED") {
          options.push(
            EnumEnquiryModules.CreateNewRequest,
            EnumEnquiryModules.ResumeRequest
          );
        } else if (
          (RepairRequest && RepairRequest.RepairStatus === "RESERVED") ||
          (RepairRequest === undefined &&
            (ServiceRequestType === "DEFAULT" ||
              ServiceRequestType === "SCRNREPREQ"))
        ) {
          options.push(EnumEnquiryModules.ChangeSchedule);
        } else {
          options.push(
            EnumEnquiryModules.ResumeRequest,
            EnumEnquiryModules.ChangeSchedule
          );
        }
        if (
          !(
            RepairRequest &&
            (["RESERVED", "SCHEDULED"].includes(RepairRequest.RepairStatus) ||
              isEmpty(RepairRequest.RepairStatus))
          )
        ) {
          options.push(EnumEnquiryModules.CancelRequest);
        }
        return options;
      case INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED:
        let noptions = [];
        noptions.push(
          EnumEnquiryModules.CreateNewRequest,
          EnumEnquiryModules.ResumeRequest
        );
        if (RepairRequest && RepairRequest.RepairStatus !== "SCHEDULED") {
          noptions.push(EnumEnquiryModules.CancelRequest);
        }
        return noptions;
      default:
        return [];
    }
  }

  const disabledOptions = () => {
    const options = optionsToBeDisabled();

    // if Servicerequests is empty disable in-warranty request
    const isClaimAlreadyFiled = Boolean(Servicerequests.length);
    if(isClaimAlreadyFiled){
      return options;
    }

    return [EnumEnquiryModules.InWarrantyRequest, ...options]
  };

  function handleClick(option) {
    setSelectedOption(option.name);
    dispatch(initModuleTrigger(option));
    setDisabled(true);
  }

  return (
    <div className="app-EnquireyOptions my-12">
      <div className="mx-auto p-4 max-w-xl">
        <BaseLabel>
          <Trans
            i18nKey="EnquiryOptions.Title"
            components={{ light: <TextLight /> }}
          />
        </BaseLabel>
        <BaseAccordian
          options={options}
          disabledKeys={disabledOptions()}
          onClick={handleClick}
          selected={selectedOption}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default EnquiryOptions;
