import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { sanitize, isEmpty } from "../../helpers/formatUtils";
import { TextMedium } from "../BaseText";

const BaseInput = ({
  value,
  onChange,
  onEnterPress,
  prefix,
  Suffix,
  placeholder,
  small,
  medium,
  full,
  className,
  disabled,
  label,
  errorText,
  id,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  const isError = !isEmpty(errorText);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 && onEnterPress) onEnterPress();
  };

  const handleInputChange = (e) => {
    const sanitnizedValue = sanitize(e.target.value);
    onChange(sanitnizedValue, e);
  };

  return (
    <>
      {(!isEmpty(label) || isError) && (
        <div
          className={classNames(
            "app-BaseInput__label",
            { "text-red-700": isError },
            { "app-BaseInput-small": small },
            { "app-BaseInput-medium": medium },
            { "app-BaseInput__label-disabled": disabled },
            {}
          )}
        >
          {isError ? (
            <TextMedium>{errorText}</TextMedium>
          ) : (
            label
          )}
        </div>
      )}
      <div
        className={classNames(
          "app-BaseInput",
          { "app-BaseInput-small": small },
          { "app-BaseInput-medium": medium },
          { "app-BaseInput-full": full },
          { "bg-srgray-septenary opacity-50": disabled },
          { "border-red-600": isError },
          { "mt-0": !isEmpty(label)},
          ...extraClasses
        )}
      >
        <div className="flex">
          {prefix && <span className="app-BaseInput__prefix">{prefix}</span>}
          <input
            id={id}
            className="app-BaseInput__input"
            value={value}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder={placeholder}
            prefix={prefix}
            disabled={disabled}
            {...restProps}
          />
          {!isError && Suffix && (
            <div className="app-BaseInput__suffix">{<Suffix />}</div>
          )}
          {isError && (
            <div className="rounded-50% bg-red-600 text-white w-10 text-center">
              <TextMedium>!</TextMedium>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

BaseInput.defaultProps = {
  disabled: false,
  errorText: "",
};

BaseInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  Suffix: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.string,
};

export default BaseInput;
