const ENQUIRY_OPTIONS = {
  Options: [
    {
      name: "CreateNewRequest",
      label: "Create a new request",
      options: [
        {
          name: "NewRequest",
          label: "Screen Repair",
        },
      ]
    },
    {
      name: "In-WarrantyRequest",
      label: "Create an In-Warranty Request",
    },
    {
      name: "ResumeRequest",
      label: "Resume an existing Request",
    },
    {
      name: "ChangeSchedule",
      label: "Change Pick-up / Return Schedule",
    },
    {
      name: "CancelRequest",
      label: "Cancel an existing request",
    },
    {
      name: "GeneralEnquiry",
      label: "General Enquiry",
    },
  ],
};

export const NEW_ENQUIRY_OPTIONS = [
  {
    name: "NewRequest Battery",
    label: "Battery Replacement",
  },
  {
    name: "NewRequest Screen & Battery",
    label: "Screen Repair and Battery Replacement",
  },
]

export const EnumEnquiryModules = {
  NewRequest: 'NewRequest',
  InWarrantyRequest: 'In-WarrantyRequest',
  ResumeRequest: 'ResumeRequest',
  ChangeSchedule: 'ChangeSchedule',
  CancelRequest: 'CancelRequest',
  GeneralEnquiry: 'GeneralEnquiry',
  CreateNewRequest: 'CreateNewRequest',
  NewRequestBatteryReplacement: 'NewRequest Battery',
  NewRequestScreenAndBattery: 'NewRequest Screen & Battery'
};

export const INCIDENT_SCREEN_REPAIR = "Screen Repair";
export const INCIDENT_SCREEN_REPAIR_WARRANTY = "Screen Repair Warranty";
export const INCIDENT_BATTERY_REPLACEMENT = "Battery Replacement";
export const INCIDENT_BATTERY_AND_SCREEN = "Bat Scr Replacement";

export const PERIL_TYPES = {
  [INCIDENT_SCREEN_REPAIR]: INCIDENT_SCREEN_REPAIR,
  [INCIDENT_SCREEN_REPAIR_WARRANTY]: INCIDENT_SCREEN_REPAIR_WARRANTY,
  [INCIDENT_BATTERY_REPLACEMENT]: "Battery Replacement",
  [INCIDENT_BATTERY_AND_SCREEN]: "Screen Repair and Battery Replacement"
}

export const PROGRAM_NAME = "SmartSupport 3.0";
// export const PROGRAM_NAME = "Starhub - Smart Support 2.1 (Singapore)";

export default ENQUIRY_OPTIONS;
