import React, { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseInformationBox from "../../../base/BaseInformationBox";

import { ReactComponent as NotesIcon } from "../../../images/icon-notes.svg";
import { importantNoteLoaded } from "../state/operators/importantNoteOperator";

const ImportantNote = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isDeviceAppleSamsung = "" } = useSelector(
    (state) => state.claim?.deviceConfirmation
  );

  useEffect(() => {
    dispatch(importantNoteLoaded());
  }, []);

  const backUpTextContent = isDeviceAppleSamsung
    ? "ImportantNote.Content.BackupDeviceOnlyAppleAndSamsung"
    : "ImportantNote.Content.BackupDevice";

  return (
    <BaseCard>
      {/* <BaseIcon icon={NotesIcon} name="Important Note" center /> */}
      <NotesIcon className="app-BaseIcon-center" />
      <BaseLabel>{t("ImportantNote.Title")}</BaseLabel>
      <BaseInformationBox>
        <BaseLabel className="text-left text-1.3">
          <ul className="list-inside list-disc">
            <li>
              <div className="pl-8 -mt-8">
                <Trans i18nKey="ImportantNote.Content.PresentDevice">
                  You will need to present your device to our courier who will
                  verify (i) your identity and (ii) the IMEI of your device at
                  the time of pick-up and return.
                  <br />
                  <br />
                </Trans>
              </div>
            </li>
            <li>
              <div className="pl-8 -mt-8">
                <Trans i18nKey={backUpTextContent} />
              </div>
            </li>
          </ul>
        </BaseLabel>
      </BaseInformationBox>
    </BaseCard>
  );
};

export default ImportantNote;
