import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  PROGRESS_DATA,
  ACTIVITY,
  API_PROGRESS,
  COMPONENTS,
} from "../../../../helpers/constants";
import {
  clearApiProgress,
  serviceUnavailable,
  updateApiProgress,
  updateFlowProgress,
} from "../../../../actions/flowWindow";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { isEmpty } from "../../../../helpers/formatUtils";
import {
  getFulFillmentApi,
  processIncidentApi,
  setFulFillmentApi,
} from "../actions/actions";
import { getServiceFee } from "../actions/paymentApi";
import { initializePickupSchedule } from "./scheduleOperator";
import { 
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY,
  INCIDENT_BATTERY_REPLACEMENT,
  INCIDENT_BATTERY_AND_SCREEN,
} from "../../../../components/EnquiryOptions/Constants";

export const submitUserAcceptanceOnTerms = (status) => async (
  dispatch,
  getStore
) => {
  const isCustomerAgreed = status === "Accept";
  let text = "",
    showComponent = "",
    module = "";
  if (isCustomerAgreed) {
    text = "Accept Terms";
    
    dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));

    const {
      selectedAsset: {
        AssetCatalog: { AssetCatalogId },
      },
      imeiVerification: { IMEI },
    } = getStore().claim.deviceConfirmation;
    const { CacheId } = getStore().session.sessionData.Configurations;
    const { triageDetails, comments } = getStore().claim.troubleshoot;
    const { caseType } = getStore().journeyMessages;

    const { isDeviceAppleSamsung = false } = getStore().claim?.deviceConfirmation;

    console.log("termsAndConditionOperator isDeviceAppleSamsung", isDeviceAppleSamsung)

    const {
      ServiceRequestId,
      CustomerCaseId,
    } = getStore().serviceRequest.serviceRequestDetails;

    const screenRepairQuestions = triageDetails.concat({
      key: "TERMS_CHECKED",
      Ans: true,
    });

    await dispatch(
      updateApiProgress(API_PROGRESS.DATA, 40, COMPONENTS.TERMS_N_CONDITIONS)
    );

    const processIncidentResponse = await dispatch(
      processIncidentApi(
        CacheId,
        CustomerCaseId,
        ServiceRequestId,
        caseType,
        AssetCatalogId,
        IMEI,
        screenRepairQuestions,
        comments
      )
    ).catch((err) => dispatch(serviceUnavailable()));
    if (isEmpty(processIncidentResponse)) return;

    let {
      CustomerCase,
      CustomerCase: { Eligibility: { EligibilityOutcome = "" } = {} } = {},
    } = processIncidentResponse.CreateIncidentResults;

    if (
      EligibilityOutcome === "Rejected" ||
      EligibilityOutcome === "Void" ||
      EligibilityOutcome === "VOID"
    ) {
      // device in-eligible
      module = "DEVICE_CONFIRMATION";
      showComponent = "deviceNotEligible";
    } else if (CustomerCase.Holds.length > 0) {
      // device hold
      module = "DEVICE_CONFIRMATION";
      showComponent = "deviceHold";
    } else {
      let getFulFillmentApiResponse = await dispatch(
        getFulFillmentApi(CacheId, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(getFulFillmentApiResponse)) return;

      let setFulFillmentApiResponse = await dispatch(
        setFulFillmentApi(caseType, CacheId, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(setFulFillmentApiResponse)) return;

      // calling service fee api here
      let encodedPerilString = encodeURIComponent(`\"${INCIDENT_SCREEN_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase()}\",\"${INCIDENT_BATTERY_REPLACEMENT.toUpperCase()}\",\"${INCIDENT_BATTERY_AND_SCREEN.toUpperCase()}\"`);
      let serviceFeeResponse = await dispatch(
        getServiceFee(CacheId, ServiceRequestId, encodedPerilString)
      ).catch((err) => {
        console.log("err", err)
        dispatch(serviceUnavailable())
      });

      console.log("serviceFeeResposnse", serviceFeeResponse)
      
      await dispatch(initializePickupSchedule(isDeviceAppleSamsung));
      showComponent = "confirmPickupAddress";
      module = "PICKUP_AND_DELIVERY";

      const initial = getStore().journeyMessages.flowProgress.percentage;
      
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.title,
          PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.count,
          initial
        )
      );
      dispatch(updateVisitor({ lastActivity: ACTIVITY.TERMS_N_CONDITIONS }));
    }
    await dispatch(
      updateApiProgress(
        API_PROGRESS.DATA_SUCCESS,
        100,
        COMPONENTS.TERMS_N_CONDITIONS,
        40
      )
    );
    await sleep(1000);
    await dispatch(clearApiProgress());
  } else {
    text = "I decline Terms & Conditions";
    await dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));

    dispatch(confirmToCancelRequest("", "T&C declined", true));
  }
  if (!isEmpty(showComponent)) {
    dispatch(
      addJourneyMessages([createMessage(module, "system", { showComponent })])
    );
  }
};
