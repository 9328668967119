import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
// import BaseInput from "../../../base/BaseInput/BaseInput";
// import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
// import BaseButton from "../../../base/BaseButton/BaseButton";
// import { TextRegular } from "../../../base/BaseText";
// import BaseTooltip from "../../../base/BaseTooltip/BaseTooltip";

import SecureIcon from "../../../images/ic-secure.jpg";
// import { ReactComponent as HelpIcon } from "../../../images/icon-help.svg";
// import { ReactComponent as VisaIcon } from "../../../images/payment/icon-visa.svg";
// import { ReactComponent as AmexSvgIcon } from "../../../images/payment/icon-amex.svg";
// import { ReactComponent as MasterCardIcon } from "../../../images/payment/icon-master-card.svg";
import { submitPaymentDetailsNew } from "../state/operators/paymentOperator";
// import { isEmpty } from "../../../helpers/formatUtils";
// import { REGEX, CARD } from "../../../helpers/constants";
// import { isValidExpiryDate } from "../../../helpers/dateUtils";

import '@backoffice/fast-payments-client-js-sdk/microui/vanilla';
import { BillingGatewayRegion, Environment, Locale, } from '@backoffice/fast-payments-client-js-sdk/microui';

import generateUniqueId from "../../../helpers/generateUniqueId";

const SubmitCardDetailsNew = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const { caseType } = useSelector((state) => state.journeyMessages);
  const perilType = caseType && caseType.replace(/\s+/g,"");

  const fee = useSelector((state) => state.claim.payment.serviceFee[perilType].TotalAmount);
  const discountedfee = useSelector((state) => state.claim.payment.serviceFee[perilType].TotalAmountWithDiscount);
  
  /*const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [cardType, setCardType] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [cvv, setCvv] = useState("");
  const [cvvError, setCvvError] = useState("");

  const handleCancelClick = () => {
    setName("");
    setNameError("");
    setCardNumber("");
    setCardNumberError("");
    setCardType("");
    setExpiryDate("");
    setExpiryDateError("");
    setCvv("");
    setCvvError("");
  };

  const handleNameChange = (val) => {
    if (!val.match(REGEX.Text)) {
      setName(val);
      setNameError("");
    }
  };

  const handleCvvChange = (input) => {
    if (!isNaN(input)) {
      setCvv(input);
      setCvvError("");
    }
  };

  const handleCardNumberChange = (input) => {
    if (!isNaN(input)) {
      setCardNumber(input);
      let cardTypeSelected = "";
      if (input.match(REGEX.Visa)) {
        cardTypeSelected = CARD.VISA;
        setCardNumberError("");
      } else if (input.match(REGEX.Amex)) {
        cardTypeSelected = CARD.AMEX;
        setCardNumberError("");
      } else if (input.match(REGEX.MasterCard)) {
        cardTypeSelected = CARD.MASTER;
        setCardNumberError("");
      } else {
        setCardNumberError(t("Payment.Input.CardNumberError"));
      }
      setCardType(cardTypeSelected);
      if (
        (cardTypeSelected === CARD.AMEX && input.length === 15) ||
        ([CARD.VISA, CARD.MASTER].includes(cardTypeSelected) && input.length === 16)
      ) {
        setCardNumberError("");
      } else {
        setCardNumberError(t("Payment.Input.CardNumberError"));
      }
    }
  };

  const handleCardType = () => {
    switch (cardType) {
      case CARD.VISA:
        return () => <VisaIcon className="mt-2" />;
      case CARD.AMEX:
        return () => <AmexSvgIcon />;
      case CARD.MASTER:
        return () => <MasterCardIcon />;
      default:
        return undefined;
    }
  };

  const handleExpiryDateChange = (value) => {
    if (!isNaN(value) || value[2] === "/") {
      const input = value.replace("/", "");
      setExpiryDate(input.length > 2 ? `${input.substr(0, 2)}/${input.substr(2, input.length - 2)}` : input);
      if (input.length === 6) {
        const month = input.substr(0, 2);
        const year = input.substr(2, 4);
        const isValid = isValidExpiryDate(month, year);
        if (!isValid) {
          setExpiryDateError(t("Payment.Input.ExpiryDateError"));
        } else {
          setExpiryDateError("");
        }
      } else {
        setExpiryDateError("");
      }
    }
  };

  const shouldntSubmit =
    disabled ||
    isEmpty(name.trim()) ||
    isEmpty(cvv) ||
    isEmpty(expiryDate) ||
    isEmpty(cardType) ||
    isEmpty(cardNumber) ||
    !isEmpty(cardNumberError) ||
    !isEmpty(expiryDateError);

  const handleSubmitClick = () => {
    if (!anyErrors()) {
      setDisabled(true);
      dispatch(
        submitPaymentDetails(name.trim(), cardNumber, expiryDate.split("/")[0], expiryDate.split("/")[1], cvv, cardType)
      );
    }
  };

  const anyErrors = () => {
    let isError = false;
    if (name.trim().length < 3) {
      setNameError(t("Payment.Input.NameError"));
      isError = true;
    }
    if (name.trim().length >= 3) {
      let nameArray = name.trim().split(' ');
      let filteredNameArray = nameArray.filter(function (el) {
        return el != null;
      });

      if (filteredNameArray.length < 2) {
        setNameError(t("Payment.Input.NameError"));
        isError = true;
      }
    }
    if (cvv.length < 3) {
      setCvvError(t("Payment.Input.CvvError"));
      isError = true;
    }
    if (expiryDate.length !== 7) {
      setExpiryDateError(t("Payment.Input.ExpiryDateError"));
      isError = true;
    }
    if (!((cardType === CARD.AMEX && cardNumber.length === 15) || cardNumber.length === 16)) {
      setCardNumberError(t("Payment.Input.CardNumberError"));
      isError = true;
    }
    return isError;
  };

  const Text = (
    <span>
      The CVV Number on your credit card or debit card is a <strong>3 digit number</strong> on VISA®, MasterCard® and
      Discover® branded credit and debit cards.
    </span>
  );
  const HelpButton = () => (
    <BaseTooltip title="" text={Text}>
      <HelpIcon className="cursor-pointer" />
    </BaseTooltip>
  );
  
  const NameLabel = <span className="text-2xl">{t("Payment.Input.NameLabel")}</span>
  const CardNumberLabel = <span className="text-2xl">{t("Payment.Input.CardNumberLabel")}</span>
  const ExpiryDateLabel = <span className="text-2xl">{t("Payment.Input.ExpiryDateLabel")}</span>
  const CvvLabel = <span className="text-2xl">{t("Payment.Input.CvvLabel")}</span>
  */

  const PCIToken = useSelector((state) => state.claim?.payment?.pciToken?.SecurityToken?.PCIToken);
  const EnrolledAddress = useSelector((state) => state.claim?.deviceConfirmation?.selectedAgreement?.EnrolledAddress || 
  state.validation.agreement[0]?.EnrolledAddress ||
  state.validation.agreement?.EnrolledAddress)
  const EmailAddress = useSelector((state) => state.validation.agreement[0]?.EmailAddress || 
  state.validation.agreement?.EmailAddress ||
  state.validation.verification?.EmailAddress);

  const [isPaymentSuccess, setisPaymentSuccess] = useState(false);
  const microUiId = generateUniqueId();

  useEffect(() => {
    if (PCIToken) {
      window.MicroUI.initializePaymentUI(microUiId, {
        billingServiceConfig: {
          environment: process.env.REACT_APP_BG_TRANSACTION_ENV === "PROD" ? Environment.PROD : Environment.QA,
          region: BillingGatewayRegion.APAC,
          applicationId: "starhub",
          billingProgramId: "STARHUB_BT-HORIZON-SCR-WEB-APAC",
        },
        applicationSession: PCIToken,
        billingInformation: {
          address: {
            address1: EnrolledAddress?.Address1,
            address2: EnrolledAddress?.Address2,
            city: EnrolledAddress?.City,
            stateOrProvince: EnrolledAddress?.StateProvinceCode,
            postalCode: EnrolledAddress?.PostalCode || "819663",
          },
          contact: {
            email: EmailAddress
          }
        },
        onPaymentSuccess: (response) => {
          console.log('Success Transaction: ', response);
          setisPaymentSuccess(true);
          dispatch(
            submitPaymentDetailsNew(
              response.paymentMethod,
              `${response?.billingInformation?.contact?.firstName} ${response?.billingInformation?.contact?.lastName}`,
              `${response?.additionalData?.cardBin}${response?.additionalData?.cardLastFourDigits}`,
              response.additionalData?.cardExpirationDate?.split("/")[0],
              response.additionalData?.cardExpirationDate?.split("/")[1],
              '',
              response.additionalData?.cardBrand,
              response.billingInformation?.address?.postalCode,
              "Continue"
            )
          );
        },
        onPaymentFailure: (response) => {
          console.log('Error Transaction: ', response);
        },
        locale: Locale.enSG,
        styleOverride: {
          fontFamily: 'DINPro-Regular, sans-serif',
          continueButtonColor: '#1ed760',
          continueButtonBorderRadius: '0',
          continueButtonHoverColor: '#1ed760',
        },
      })
    }
  }, [PCIToken])

  return (
    <BaseCard className={`pb-12 ${isPaymentSuccess ? "pointer-events-none" : ""}`} style={{ maxWidth: "50rem" }}>
      <img className="app-BaseIcon-center" src={SecureIcon} alt="secure-icon"/>
      <BaseLabel className="my-5">
        <Trans i18nKey={`Payment.${perilType}.Title`}>
          A Screen Repair fee of <strong>${{ fee: discountedfee || fee }}</strong><br />(inclusive of GST) is applicable.
        </Trans>
      </BaseLabel>

      <div id="paymentUI"><div id={microUiId} /></div>

    </BaseCard>
    // <BaseCard>
    //   <img className="app-BaseIcon-center" src={SecureIcon} alt="secure-icon"/>
    //   <BaseLabel className="my-2">
    //     <Trans i18nKey={`Payment.${perilType}.Title`}>
    //       A Screen Repair fee of <strong>${{fee: discountedfee || fee}}</strong><br />(inclusive of GST) is applicable.
    //     </Trans>
    //   </BaseLabel>
    //   <BaseLabel className="my-3">
    //     <Trans i18nKey="Payment.Label">
    //       <TextRegular>
    //         Please provide your
    //         <br /> Credit Card/Debit Card details below
    //       </TextRegular>
    //     </Trans>
    //   </BaseLabel>
    //   <BaseInput
    //     showLabel={true}
    //     className="mb-1"
    //     placeholder={t("Payment.Input.Name")}
    //     label={NameLabel}
    //     value={name}
    //     onChange={handleNameChange}
    //     disabled={disabled}
    //     full
    //     errorText={nameError}
    //   />
    //   <BaseInput
    //     showLabel={true}
    //     className="mt-0 mb-1"
    //     placeholder={t("Payment.Input.CardNumber")}
    //     label={CardNumberLabel}
    //     value={cardNumber}
    //     onChange={(val) => handleCardNumberChange(val.trim())}
    //     disabled={disabled}
    //     maxLength={16}
    //     full
    //     Suffix={handleCardType()}
    //     errorText={cardNumberError}
    //   />
    //   <BaseInput
    //     showLabel={true}
    //     className="mt-0 mb-1"
    //     placeholder={t("Payment.Input.ExpiryDate")}
    //     label={ExpiryDateLabel}
    //     value={expiryDate}
    //     onChange={(val) => handleExpiryDateChange(val.trim())}
    //     disabled={disabled}
    //     maxLength={7}
    //     errorText={expiryDateError}
    //     full
    //   />
    //   <BaseInput
    //     showLabel={true}
    //     type="password"
    //     className="mt-0"
    //     placeholder={t("Payment.Input.Cvv")}
    //     label={CvvLabel}
    //     value={cvv}
    //     onChange={(val) => handleCvvChange(val.trim())}
    //     disabled={disabled}
    //     maxLength={4}
    //     full
    //     Suffix={HelpButton}
    //     errorText={cvvError}
    //   />
    //   <BaseButtonGroup>
    //     <BaseButton
    //       className="bg-app-nova border-app-nova"
    //       text={t("SubmitButton")}
    //       onClick={handleSubmitClick}
    //       disabled={shouldntSubmit}
    //       clicked={disabled}
    //     />
    //     <BaseButton text={t("ClearButton")} onClick={handleCancelClick} disabled={disabled} className="px-16" />
    //   </BaseButtonGroup>
    // </BaseCard>
  );
};

export default SubmitCardDetailsNew;
