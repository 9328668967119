import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import classNames from "classnames";
import PropTypes from "prop-types";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseCheckbox from "../../../base/BaseCheckbox/BaseCheckbox";
import { TextBold, TextRegular } from "../../../base/BaseText";
import BaseTextarea from "../../../base/BaseTextarea/BaseTextarea";

import { submitTroubleshootAnswer } from "../state/operators/troubleshootingOperator";
import { handleBackToMainMenu } from "../state/operators/deviceConfirmationOperators";
import tsConfig from "./troubleshootConfig";
import Damage from "./Damage";
import { DEVICE } from "../../../helpers/constants";

const InWarrantyComments = ({ comments, onChange, disabled }) => (
  <div>
    <BaseLabel className="text-left">
      <TextBold>
        <Trans i18nKey="Troubleshoot.InWarrantyCommentLabel" />
      </TextBold>
    </BaseLabel>
    <BaseTextarea
      className="mt-2"
      value={comments}
      onChange={onChange}
      disabled={disabled}
    />
  </div>
);

const BatteryHealthCheckModal = ({
  showModal,
  isDeviceAppleSamsung,
  make,
  onClick,
}) => {
  const wrapperClass = classNames({
    "absolute inset-0 bg-gray-700 bg-opacity-50 overflow-hidden": showModal,
  });

  const contentClass = classNames(
    "w-full absolute bottom-0 rounded-tr-3xl rounded-tl-3xl bg-white z-10 h-60% drawer-translate-y-full transition-transform ease-out duration-300",
    { "drawer-translate-y-0": showModal }
  );

  const { IOSDevice, AndroidDevice } = tsConfig;
  const Device = make.toUpperCase() === DEVICE.APPLE ? IOSDevice: AndroidDevice;

  return (
    <div className={wrapperClass}>
      <div className={contentClass}>
        <div
          className="text-right p-8 text-2xl"
          style={{ color: "#007AFF" }}
          onClick={onClick}
        >
          <TextRegular>Close</TextRegular>
        </div>
        { isDeviceAppleSamsung && <Device /> }
      </div>
    </div>
  );
};

const Troubleshoot = ({ perilType, inWarranty }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [triage, setTriage] = useState(null);
  const [status, setStatus] = useState(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [comments, setComments] = useState("");
  const { isDeviceAppleSamsung = false, selectedAsset } = useSelector(
    (state) => state.claim?.deviceConfirmation
  );
  const make = selectedAsset?.Make?.Name || "Other";
  
  const getConfig = () => {
    const { getDamagesByPerilType } = tsConfig;
    return getDamagesByPerilType(perilType, isDeviceAppleSamsung);
  };

  useEffect(() => {
    const config = getConfig();
    const _traige = Object.keys(config).reduce((acc, dmg) => {
      const question = t(`Troubleshoot.${perilType}.${dmg}.Title`)
        .replace(/[<0></0>]/g, "")
        .trim();
      const cancelReason = t(`Troubleshoot.${perilType}.${dmg}.CancelReason`);
      const result = null;
      acc[dmg] = {
        question,
        cancelReason,
        result,
      };
      return acc;
    }, {});

    setTriage(_traige);
  }, []);

  const handleYesClick = (type) => {
    const result = Object.assign({}, triage, {
      [type]: {
        ...triage[type],
        result: "Yes",
      },
    });

    setTriage(result);
  };

  const handleNoClick = (type) => {
    const result = Object.assign({}, triage, {
      [type]: {
        ...triage[type],
        result: "No",
      },
    });

    setTriage(result);
  };

  const handleLinkClick = () => setShowModal(!showModal);

  const getTriageDetails = () =>
    triage &&
    Object.keys(triage).map((type) => {
      const triageDetail = triage[type];
      const isYes = triageDetail.result === "Yes";
      return {
        key: type,
        value: isYes,
        question: triageDetail.question,
        cancelReason: !isYes && triageDetail.cancelReason,
      };
    });

  const handleSubmit = (type) => {
    setStatus(type);
    setDisabled(true);
    if (type === "BackButton") {
      dispatch(handleBackToMainMenu(t("BackButton")));
      return;
    }

    const triageDetails = getTriageDetails();
    if (triageDetails && triageDetails.length) {
      dispatch(submitTroubleshootAnswer(triageDetails, comments));
    }
  };

  const TroubleshootContent = () => {
    const config = getConfig();
    return (
      <div className="flex flex-col items-center justify-center space-y-6 px-4">
        {Object.keys(config).map((dmgType) => {
          const { icon, title, notes, link } = config[dmgType];
          const notesByPerilType = notes?.[perilType] || [];
          return (
            <Damage
              perilType={perilType}
              damageType={dmgType}
              icon={icon}
              title={title}
              triage={triage}
              notes={notesByPerilType}
              link={link}
              disabled={disabled}
              handleYesClick={() => handleYesClick(dmgType)}
              handleNoClick={() => handleNoClick(dmgType)}
              handleLinkClick={handleLinkClick}
            />
          );
        })}
      </div>
    );
  };

  const getTriageStatus = () => {
    if (triage) {
      const areAllTriageQuestionClicked = Object.keys(triage).some((key) => {
        const triageDetail = triage[key];
        return triageDetail.result === null;
      });

      return !(isAgreed && !areAllTriageQuestionClicked);
    }

    return true;
  };

  const confirmBtnDisableStatus = disabled || getTriageStatus();
  const checkboxLabel = (
    <BaseLabel className="text-2xl text-left my-0">
      <TextRegular>
        <Trans
          i18nKey={`Troubleshoot.${
            inWarranty ? "InWarrantyTermsAndConditions" : "TermsAndConditions"
          }`}
        />
      </TextRegular>
    </BaseLabel>
  );

  return (
    <>
      <BaseCard className="space-y-4">
        <BaseLabel className="text-left flex justify-center mb-0">
          <TextBold>
            <Trans i18nKey={`Troubleshoot.${perilType}.Header`} />
          </TextBold>
        </BaseLabel>
        <TroubleshootContent />
        <BaseCheckbox
          className="px-4 py-0 m-0"
          id="toc-troubleshoot"
          value={isAgreed}
          onChange={() => setIsAgreed(!isAgreed)}
          label={checkboxLabel}
          disabled={disabled}
        />
        {inWarranty && (
          <InWarrantyComments
            comments={comments}
            onChange={(val) => setComments(val)}
            disabled={disabled}
          />
        )}
        <BaseButtonGroup>
          <BaseButton
            className="px-12"
            text={t("ConfirmButton")}
            onClick={() => handleSubmit("Confirm")}
            disabled={confirmBtnDisableStatus}
            clicked={status === "Confirm"}
          />
          <BaseButton
            className="px-16"
            text={t("BackButton")}
            onClick={() => handleSubmit("BackButton")}
            disabled={disabled}
            clicked={status === "BackButton"}
          />
        </BaseButtonGroup>
      </BaseCard>
      <BatteryHealthCheckModal
        showModal={showModal}
        isDeviceAppleSamsung={isDeviceAppleSamsung}
        make={make}
        onClick={() => setShowModal(false)}
      />
    </>
  );
};

Troubleshoot.defaultProps = {
  inWarranty: false,
};

Troubleshoot.propTypes = {
  perilType: PropTypes.string.isRequired,
  inWarranty: PropTypes.bool,
};

export default Troubleshoot;
