import React, { useState } from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import { ReactComponent as CancelIcon } from "../../../images/icon-request-cancel.svg";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeAgentBusy } from "../state/operators";
import { TextLight } from "../../../base/BaseText";

const ChatUnresolved = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const { chatReason } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const contentText =
    chatReason === "Invalid Name" ||
    chatReason === "Invalid Nric" ||
    chatReason === "Invalid Imei"
      ? "Validation"
      : "Generic";

  const handleCloseClick = () => {
    dispatch(closeAgentBusy(t("CloseButton")));
    setDisabled(true);
  };

  return (
    <div className="w-full my-8 text-center">
      {/* <BaseIcon icon={cancelIcon} name="Agent" center /> */}
      <CancelIcon className="app-BaseIcon-center" />
      <div className="text-center text-default leading-7 text-srgray-primary w-32r mx-auto">
        <Trans i18nKey={`Chat.ChatUnresolved.${contentText}`}>
          <TextLight>text</TextLight>
        </Trans>
      </div>
      <BaseButton
        text={t("CloseButton")}
        onClick={handleCloseClick}
        disabled={disabled}
        className="mx-auto px-12 mt-8"
      />
    </div>
  );
};

export default ChatUnresolved;
