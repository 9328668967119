import { createSlice } from "@reduxjs/toolkit";

let initialState = {};

const serviceRequestReducer = createSlice({
  name: "serviceRequest",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callDetermineRequestApi(state, action) {
      state.determineIncidentDetails = action.payload || {};
    },
    callDetermineRequestApiSuccess(state, action) {
      state.type = action.payload.IncidentPath.Type;
      state.determineIncidentDetails = action.payload.IncidentPath;
    },
    callDetermineRequestApiFailure(state, action) {
      state.error = action.payload;
    },
    callCreateServiceRequestApi(state, action) {
      state.serviceRequestDetails = action.payload || {};
    },
    callCreateServiceRequestApiSuccess(state, action) {
      state.serviceRequestDetails = action.payload.ServiceRequestDetails.CreateServiceRequestResults;
    },
    callCreateServiceRequestApiFailure(state, action) {
      state.error = action.payload;
    },
    callCancelServiceRequestApi(state, action) {
      state.serviceRequestDetails = action.payload || {};
    },
    callCancelServiceRequestApiSuccess(state, action) {
      const data = action.payload.CancelServiceResponse.Result;
      state.serviceRequestDetails = {
        ...data,
        ...data.CustomerCases[0],
        ...data.ServiceRequests[0],
      };
    },
    callCancelServiceRequestApiFailure(state, action) {
      state.error = action.payload;
    },
    callResumeServiceRequestApi(state, action) {
      state.serviceRequestDetails = {};
    },
    callResumeServiceRequestApiSuccess(state, action) {
      state.serviceRequestDetails = action.payload.ServiceRequestDetails;
    },
    callResumeServiceRequestApiFailure(state, action) {
      state.error = action.payload;
    },
    callInquiryApi(state, action) {
      state.Inquiry = {};
    },
    callInquiryApiSuccess(state, action) {
      // let { TLCEventUpdateresponse = {} } = action.payload.CreateInquiryResponse;
      state.Inquiry = action.payload.CreateInquiryResponse;
      // state.createInquiryResponse = action.payload.CreateInquiryResponse;
      // state.currentDeliveryTrackingUrl =
      //   TLCEventUpdateresponse &&
      //   TLCEventUpdateresponse.TrackingUrl &&
      //   TLCEventUpdateresponse.TrackingUrl.replace("https", "https");
      // state.currentDeliveryTrackingStatus = TLCEventUpdateresponse.Status;
    },
    callInquiryApiFailure(state, action) {
      state.error = action.payload;
    }
  },
});

const { actions, reducer } = serviceRequestReducer;
export const {
  callDetermineRequestApi,
  callCreateServiceRequestApi,
  callCancelServiceRequestApi,
  callResumeServiceRequestApi,
  callInquiryApi
} = actions;
export default reducer;
