import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseCard from "../../base/BaseCard/BaseCard";
import BaseLabel from "../../base/BaseLabel";
import BaseButton from "../../base/BaseButton/BaseButton";

import { ReactComponent as AgentIcon } from "../../images/icon-chat-agent.svg";
import { isEmpty } from "../../helpers/formatUtils";
import { addJourneyMessages, sleep } from "../../helpers/addJourneyMessages";
import createMessage from "../../helpers/createMessage";

const ToggleMyAsurion = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mdn, setMdn] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState('');

  const handleNoClick = () => {
    setSelected('beta-myasurion-no')
    setDisabled(true);
    dispatch(
      addJourneyMessages([
        createMessage("TEXT","user", t("BetaTest.NoButton")),
        createMessage("VALIDATION", "system", { showComponent: "mdn" }),
      ])
    );
  };

  const handleYesClick = async () => {
    setSelected('beta-myasurion-yes')
    setDisabled(true);
    dispatch(
      addJourneyMessages([
        createMessage("TEXT","user",t("BetaTest.YesButton")),
      ])
    );
    await sleep(1000);
    window.location = process.env.REACT_APP_MY_ASURION_SUR_REDIRECT_URL
  };

  const shouldSubmit = disabled || isEmpty(mdn) || mdn.length !== 8;

  return (
    <BaseCard>

      <div className="flex flex-row mb-4 justify-around gap-4">
        <div className="pl-4">
          <AgentIcon className="app-BaseIcon-center" />
        </div>
        <BaseLabel className="text-left font-bold my-auto text-center pr-4">{t("BetaTest.Title")}</BaseLabel>
      </div>

      <p className="text-center text-1.3 leading-tight px-4">
        {t("BetaTest.Subtitle")}
      </p>

      <div className="px-4 py-4">
        <BaseButton
          id="beta-myasurion-yes"
          name="beta-myasurion-yes"
          text={t("BetaTest.YesButton")}
          onClick={handleYesClick}
          clicked={selected === 'beta-myasurion-yes'}
          disabled={disabled}
          className="mx-auto w-full mb-0"
        />
        <BaseButton
          id="beta-myasurion-no"
          name="beta-myasurion-no"
          text={t("BetaTest.NoButton")}
          onClick={handleNoClick}
          clicked={selected === 'beta-myasurion-no'}
          disabled={disabled}
          className="mx-auto w-full mb-0"
        />
      </div>

    </BaseCard>
  );
};

export default ToggleMyAsurion;
