import React, { useState } from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import { ReactComponent as AgentIcon } from "../../../images/icon-chat-agent.svg";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeAgentBusy } from "../state/operators";
import { TextLight, TextMedium } from "../../../base/BaseText";
import BaseLabel from "../../../base/BaseLabel";

const TechnicalIssue = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(closeAgentBusy(t("CloseButton")));
    setDisabled(true);
  };

  return (
    <div className="w-full my-8 text-center">
      {/* <BaseIcon className="w-24 h-24" icon={agentIcon} name="Agent" center /> */}
      <AgentIcon className="app-BaseIcon-center w-24 h-24" />
      <BaseLabel>
        <TextMedium>{t("Chat.TechnicalIsuue.Title")}</TextMedium>
      </BaseLabel>
      <div className="text-center text-default leading-7 text-srgray-primary w-32r mx-auto">
        <TextLight>{t("Chat.TechnicalIsuue.Content")}</TextLight>
      </div>
      <BaseButton
        text={t("CloseButton")}
        onClick={handleCloseClick}
        disabled={disabled}
        className="mx-auto w-11r mt-8"
      />
    </div>
  );
};

export default TechnicalIssue;
