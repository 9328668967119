import React from "react";
import { Trans } from "react-i18next";
import IssuesIcon from "../../../images/icon-request-cancel.svg";
import { TextLight, TextMedium } from "../../../base/BaseText";
import IncorrectScreen from "../../../components/IncorrectScreen";
import { useSelector } from "react-redux";
import { EnumEnquiryModules } from "../../../components/EnquiryOptions/Constants";

const RequestCancelled = () => {
  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );

  const caseNumber = useSelector((state) =>
    enquiryOption === EnumEnquiryModules.CancelRequest
      ? state.serviceRequest.determineIncidentDetails.ServiceRequestNumber
      : state.serviceRequest.serviceRequestDetails.ServiceRequestNumber
  );

  return (
    <IncorrectScreen icon={IssuesIcon} name="issues">
      <Trans i18nKey="RequestCancelled.Title">
        <TextLight>
          Your Repair Request case{" "}
          <TextMedium>{{ caseNumber }}</TextMedium> has been cancelled. Thank
          you for using Repair Service.
        </TextLight>
      </Trans>
    </IncorrectScreen>
  );
};

export default RequestCancelled;
