import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextMedium } from "../BaseText";

const BaseButton = ({
  text,
  onClick,
  clicked,
  disabled,
  className,
  autoFocus,
  focused,
  id,
  startAdornment,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <div
      className={classNames(
        "app-BaseButton",
        {
          "app-BaseButton-normal": !disabled && !clicked,
          "app-BaseButton-clicked": clicked,
          "app-BaseButton-normal-focused": !disabled && (autoFocus || focused),
          "app-BaseButton-disabled": disabled || clicked,
        },
        ...extraClasses
      )}
      {...(!disabled && (onClick = { onClick }))}
    >
      {startAdornment}
      {disabled ? (
        <div className="app-BaseButton__btn">
          <TextMedium>{text}</TextMedium>
        </div>
      ) : (
        <button
          id={id}
          className="app-BaseButton__btn"
          {...restProps}
          autoFocus={autoFocus}
        >
          <TextMedium>{text}</TextMedium>
        </button>
      )}
    </div>
  );
};

BaseButton.defaultProps = {
  clicked: false,
  autoFocus: false,
  focused: true,
  startAdornment: null
};

BaseButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  clicked: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  focused: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  startAdornment: PropTypes.any
};

export default BaseButton;
