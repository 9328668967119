import { addJourneyMessages, sleep } from "../helpers/addJourneyMessages";
import createMessage from "../helpers/createMessage";
import { EnumEnquiryModules } from "../components/EnquiryOptions/Constants";
import { initializePickupSchedule } from "../modules/RepairClaim/state/operators/scheduleOperator";
import {
  setCaseType,
  setEnquiryOption,
} from "../reducers/journeyMessagesReducer";
import {
  PROGRESS_DATA,
  API_PROGRESS,
  COMPONENTS,
  ACTIVITY,
  INCIDENT_PATH_TYPE,
} from "../helpers/constants";
import {
  updateFlowProgress,
  updateApiProgress,
  clearApiProgress,
  serviceUnavailable,
} from "./flowWindow";
import {
  cancelServiceRequest,
  determineRequest,
  resumeServiceRequest,
} from "./serviceRequestApi";
import updateVisitor from "../modules/Appsync/updateVisitor";
import {
  INCIDENT_SCREEN_REPAIR,
  INCIDENT_SCREEN_REPAIR_WARRANTY,
  INCIDENT_BATTERY_REPLACEMENT,
  INCIDENT_BATTERY_AND_SCREEN,
} from "../components/EnquiryOptions/Constants";
import { setChatReason } from "../modules/Appsync/state/reducers";
import { udpateChatInputVisibility } from "../modules/Appsync/state/operators";
import { isEmpty } from "../helpers/formatUtils";
import { getServiceFee } from "../modules/RepairClaim/state/actions/paymentApi";
import { setDeviceMakeAndModel } from "../modules/RepairClaim/state/actions/actions";
import { getEnrolledServiceFee } from "./cowrapperapi";
import { getFulFillmentApi, setFulFillmentApi } from "../modules/RepairClaim/state/actions/actions";

const incidentTypeByName = {
  "NewRequest": INCIDENT_SCREEN_REPAIR,
  "NewRequest Battery": INCIDENT_BATTERY_REPLACEMENT,
  "NewRequest Screen & Battery": INCIDENT_BATTERY_AND_SCREEN
}

const incidentTypeFromDetermineResponse = [
  EnumEnquiryModules.ResumeRequest,
  EnumEnquiryModules.CancelRequest
];

const noIncidentTypeRequired = [
  EnumEnquiryModules.ChangeSchedule,
  EnumEnquiryModules.GeneralEnquiry
]

const getCaseType = (selectedOption, store) => {
  const isInWarranty = selectedOption === "In-WarrantyRequest";
  
  if(isInWarranty){
    const caseType = store.serviceRequest?.determineIncidentDetails?.Servicerequests?.[0]?.IncidentType;
    const isScreenRepair = caseType === INCIDENT_SCREEN_REPAIR;
    return isScreenRepair ? INCIDENT_SCREEN_REPAIR_WARRANTY : caseType;
  }

  const otherRequest = incidentTypeFromDetermineResponse.includes(selectedOption)
  if(otherRequest){
    const caseType = store.serviceRequest?.determineIncidentDetails?.ServiceRequest?.IncidentTypeCode;
    return caseType;
  }

  return incidentTypeByName[selectedOption];
}

export const initModuleTrigger = (option) => async (dispatch, getStore) => {
  
  const labelMessage = createMessage("TEXT", "user", option.label);
  dispatch(saveEnquiryOption(option.name));

  /**
   * caseType is not required for General inquiry and change schedule
   */
  const caseTypeNotRequired = noIncidentTypeRequired.includes(option)
  if(!caseTypeNotRequired){
    const caseType = getCaseType(option.name, getStore());
    dispatch(saveCaseType(caseType));
  }
  
  dispatch(
    updateVisitor({
      lastActivity: `${option.name} ${ACTIVITY.ENQUIRY_OPTIONS}`,
      interactionType: option.name,
    })
  );

  const incidentPathType = getStore().serviceRequest.type;
  
  switch (option.name) {
    case EnumEnquiryModules.NewRequest:
    case EnumEnquiryModules.NewRequestBatteryReplacement:
    case EnumEnquiryModules.NewRequestScreenAndBattery:
      if (incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST) {
        // cancel SR
        await dispatch(
          updateApiProgress(
            API_PROGRESS.TROUBLESHOOT,
            40,
            COMPONENTS.ENQUIRY_OPTIONS
          )
        );
        const {
          ServiceRequestId,
          CustomerCaseId,
        } = getStore().serviceRequest.determineIncidentDetails;
        const { CacheId: cacheForNR } = getStore().session.sessionData.Configurations;
        const note = "Request Cancelled by User";

        const cancelSRResponse = await dispatch(
          cancelServiceRequest(
            cacheForNR,
            ServiceRequestId,
            note,
            "",
            CustomerCaseId,
            "",
            ""
          )
        ).catch((err) => dispatch(serviceUnavailable()));
        if (isEmpty(cancelSRResponse)) return;

        const determineResponse = await dispatch(
          determineRequest(cacheForNR)
        ).catch((err) => dispatch(serviceUnavailable()));
        if (isEmpty(determineResponse)) return;

        await dispatch(
          updateApiProgress(
            API_PROGRESS.TROUBLESHOOT_SUCCESS,
            100,
            COMPONENTS.ENQUIRY_OPTIONS,
            40
          )
        );
        await sleep(1000);
        await dispatch(clearApiProgress());
      }
      const {
        interactionData: {
          Interaction: {
            InteractionLineId,
            SessionData: { ClientId, ClientChannelId, ClientName },
          },
        },
      } = getStore().session;
      const { mdn } = getStore().validation.inputData;
      const { CacheId } = getStore().session.sessionData.Configurations;
      dispatch(
        getEnrolledServiceFee(
          ClientId,
          ClientChannelId,
          ClientName,
          InteractionLineId,
          mdn,
          CacheId
        )
      );
      dispatch(initializePickupSchedule());
      const initial = getStore().journeyMessages.flowProgress.percentage;
      dispatch(
        updateFlowProgress(
          PROGRESS_DATA.PREPARE_REQUIREMENTS.title,
          PROGRESS_DATA.PREPARE_REQUIREMENTS.count,
          initial
        )
      );
      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "preparation",
          }),
        ])
      );
      break;
    case EnumEnquiryModules.ChangeSchedule:
      // initiating chat
      await dispatch(startChatFlow(labelMessage, option.name));
      break;
    case EnumEnquiryModules.CancelRequest:
      const {
        ServiceRequestType,
        RepairRequest,
      } = getStore().serviceRequest.determineIncidentDetails;

      let showComponent = "";
      let showModule = "";

      if (
        incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED &&
        ServiceRequestType !== "SCRNREPREQ"
      ) {
        // device in-eligible for screen repair
        showModule = "DEVICE_CONFIRMATION";
        showComponent = "deviceNotEligible";
      } else if (
        RepairRequest &&
        (RepairRequest.RepairStatus === "RESERVED" ||
          RepairRequest.RepairStatus === "SCHEDULED")
      ) {
        await dispatch(initializePickupSchedule());
        showComponent = "";
        showModule = "REPAIR_DETAILS";
      } else if (
        RepairRequest &&
        RepairRequest.RepairStatus === "STARTED"
      ) {
        // redirect to chat
        await dispatch(startChatFlow(labelMessage, option.name));
        return;
      } else if (
        incidentPathType === INCIDENT_PATH_TYPE.RESUME_SERVICE_REQUEST
      ) {
        showModule = "CANCEL_REPAIR";
        showComponent = "ConfirmRequestCancel";
      }

      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage(showModule, "system", { showComponent }),
        ])
      );
      break;
    case EnumEnquiryModules.ResumeRequest:
      let selectedComponent = "";
      let selectedModule = "";
      const { CacheId: cacheForRR } = getStore().session.sessionData.Configurations;
      const {
        ServiceRequestId,
        ClaimedAsset,
      } = getStore().serviceRequest.determineIncidentDetails;

      dispatch(addJourneyMessages([labelMessage]));
      const module = API_PROGRESS.RESUME_REQUEST,
        module_success = API_PROGRESS.RESUME_REQUEST_SUCCESS,
        component = COMPONENTS.ENQUIRY_OPTIONS;
      await dispatch(updateApiProgress(module, 40, component));

      const resumeSRResponse = await dispatch(
        resumeServiceRequest(cacheForRR, ServiceRequestId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(resumeSRResponse)) return;
      if (!isEmpty(resumeSRResponse.ServiceRequestDetails.IncidentType)) {
        dispatch(
          saveCaseType(resumeSRResponse.ServiceRequestDetails.IncidentType)
        );
      }

      await dispatch(updateApiProgress(module_success, 100, component, 40));
      await sleep(1000);
      await dispatch(clearApiProgress());

      // redirect to resume points
      const {
        Actions,
        SRAssetCatalogName,
        SRAssetCatalogId,
      } = resumeSRResponse.ServiceRequestDetails;

      if (Actions === "CaptureIncidentDetails") {
        const { replacedAsset } = getStore().validation.verification;

        selectedComponent = isEmpty(replacedAsset)
          ? "confirmDevice"
          : "displayDevices";

        selectedModule = "DEVICE_CONFIRMATION";
      } else if (
        Actions === "SelectReplacementEquipment" ||
        Actions === "ConfirmShippingAddress" ||
        Actions === "CaptureShippingMethod" ||
        Actions === "OrderFulFillment" ||
        Actions === "CapturePaymentDetails"
      ) {
        const { caseType } = getStore().journeyMessages;
        if (!resumeSRResponse?.ServiceRequestDetails?.FulfillmentOption) {
          // let getFulFillmentApiResponse = await dispatch(
          //   getFulFillmentApi(cacheForRR, ServiceRequestId)
          // ).catch((err) => dispatch(serviceUnavailable()));
          // if (isEmpty(getFulFillmentApiResponse)) return;

          let setFulFillmentApiResponse = await dispatch(
            setFulFillmentApi(caseType, cacheForRR, ServiceRequestId)
          ).catch((err) => dispatch(serviceUnavailable()));
          if (isEmpty(setFulFillmentApiResponse)) return;
        }
        
        // save asset details
        const deviceDetails = {
          AssetCatalog: {
            AssetCatalogId: SRAssetCatalogId,
            AssetCatalogName: SRAssetCatalogName,
          },
          Make: {
            MakeId: "",
            Name: ClaimedAsset.Make,
          },
          Model: {
            ModelId: "",
            Name: ClaimedAsset.Model,
          },
          IMEI: "",
        };

        dispatch(setDeviceMakeAndModel(deviceDetails));

        // calling service fee api here
        let encodedPerilString = encodeURIComponent(`\"${INCIDENT_SCREEN_REPAIR.toUpperCase()}\",\"${INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase()}\",\"${INCIDENT_BATTERY_REPLACEMENT.toUpperCase()}\",\"${INCIDENT_BATTERY_AND_SCREEN.toUpperCase()}\"`);
        let serviceFeeResposnse = await dispatch(getServiceFee(cacheForRR, ServiceRequestId, encodedPerilString))
        .catch((err) => {
          console.log("err", err)
          dispatch(serviceUnavailable())
        });

        console.log("serviceFeeResposnse", serviceFeeResposnse)

        if (isEmpty(serviceFeeResposnse)) return;

        dispatch(initializePickupSchedule());
        selectedComponent = "confirmPickupAddress";
        selectedModule = "PICKUP_AND_DELIVERY";

        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(
          updateFlowProgress(
            PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.title,
            PROGRESS_DATA.CONFIRM_PICKUP_DETAILS.count,
            initial
          )
        );
        dispatch(updateVisitor({ lastActivity: ACTIVITY.TERMS_N_CONDITIONS }));
      }

      dispatch(
        addJourneyMessages([
          createMessage(selectedModule, "system", { showComponent: selectedComponent }),
        ])
      );
      break;
    case EnumEnquiryModules.GeneralEnquiry:
      dispatch(
        addJourneyMessages([
          labelMessage,
          createMessage("GENERAL_ENQUIRY", "system", {
            showComponent: "GeneralEnquiryOptions",
          }),
        ])
      );
      break;
    case EnumEnquiryModules.InWarrantyRequest:
      {
        const {
          interactionData: {
            Interaction: {
              InteractionLineId,
              SessionData: { ClientId, ClientChannelId, ClientName },
            },
          },
        } = getStore().session;
        const { mdn } = getStore().validation.inputData;
        const { CacheId } = getStore().session.sessionData.Configurations;

        dispatch(
          getEnrolledServiceFee(
            ClientId,
            ClientChannelId,
            ClientName,
            InteractionLineId,
            mdn,
            CacheId
          )
        );
        
        dispatch(initializePickupSchedule());
        const initial = getStore().journeyMessages.flowProgress.percentage;
        dispatch(
          updateFlowProgress(
            PROGRESS_DATA.PREPARE_REQUIREMENTS.title,
            PROGRESS_DATA.PREPARE_REQUIREMENTS.count,
            initial
          )
        );
        dispatch(
          addJourneyMessages([
            labelMessage,
            createMessage("DEVICE_CONFIRMATION", "system", {
              showComponent: "preparation",
            }),
          ])
        );
      }
      break;
    default:
      dispatch(addJourneyMessages([labelMessage]));
  }
};

const saveEnquiryOption = (option) => (dispatch) => {
  dispatch({
    type: setEnquiryOption.toString(),
    payload: option,
  });
};

const saveCaseType = (caseType) => (dispatch) => {
  dispatch({
    type: setCaseType.toString(),
    payload: caseType,
  });
};

const startChatFlow = (labelMessage, option) => async (dispatch) => {
  dispatch(addJourneyMessages([labelMessage]));
  await dispatch(setChatReason(option));
  dispatch(udpateChatInputVisibility(true));
};
