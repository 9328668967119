import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import BaseCard from "../../base/BaseCard/BaseCard";
import BaseInput from "../../base/BaseInput/BaseInput";
import BaseLabel from "../../base/BaseLabel";
import BaseInformationBox from "../../base/BaseInformationBox";
import BaseButtonGroup from "../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../base/BaseButton/BaseButton";
import BasePopup from "../../base/BasePopup/BasePopup";
import { TextRegular } from "../../base/BaseText";

import { isEmpty } from "../../helpers/formatUtils";
import WelcomeToService from "../../components/WelcomeToService/WelcomeToService";
import Config from "../Appsync/Config";
import { FIELD_IDS } from "../../helpers/constants";
import { validateMdn } from "./state/operators";
import classNames from "classnames";


const ModalTitle = ({ title }) => <span className="font-bold">{title}</span>;

const AskMdn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mdn, setMdn] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [freezeWindow, setFreezeWindow] = useState(false);
  const [token, setToken] = useState(null)
  const [error, setError] = useState();

  const agreement = useSelector(state => state.validation?.agreement);
  useEffect(() => {
    if (agreement?.AgreementFailedAttempt > 0) {
      setError(agreement.error)
      const freeze = agreement?.AgreementFailedAttempt === 3;
      setFreezeWindow(freeze)
    }
  }, [agreement?.AgreementFailedAttempt])


  const handleMdnChange = (value) => {
    if (!isNaN(value.trim())) setMdn(value.trim());
  };

  const submitMdn = async () => {
    dispatch(validateMdn(mdn));
    setFreezeWindow(true);
  };

  const verifyCaptcha = (res) => {
    setToken(res)
  };

  const shouldSubmit = freezeWindow || isEmpty(mdn) || mdn.length !== 8 || !token;

  const ErrorComponent = () => <BaseLabel className="text-text-error text-2xl text-left my-1 mx-2">
    <Trans i18nKey={error} />
  </BaseLabel>

  const captchaClass = classNames(
    "my-4 ml-2 transform scale-90",
    { "pointer-events-none": freezeWindow }
  )
  return (
    <>
      <div className="app-AskMdn">
        <BaseCard className="pt-12 pb-8">
          <WelcomeToService />
          <BaseLabel>
            <div className="text-default">
              <Trans i18nKey="AskMdn.Label">
                For an uninterrupted experience, <br />

                <span
                  className="text-app-secondary font-Lato-Regular cursor-pointer"
                  onClick={() => setShowPopup(true)}
                >
                  please check that you are eligible to access this Screen Repair and Battery Replacment Service
                </span>
                and please have ready:
              </Trans>
            </div>
          </BaseLabel>
          <BaseInformationBox className="mx-2">
            <ul className="list-disc list-inside text-default">
              <li>
                <Trans i18nKey="AskMdn.Information.1">
                  Your National ID/NRIC/FIN number
                </Trans>
              </li>
              <li>
                <Trans i18nKey="AskMdn.Information.2">
                  Device make, model and IMEI number
                </Trans>
              </li>
            </ul>
          </BaseInformationBox>
          <div className="px-2">
            <BaseInput
              id={FIELD_IDS.MDN}
              className="mb-0 w-full"
              value={mdn}
              label={t("AskMdn.InputLabel")}
              placeholder={t("AskMdn.Placeholder")}
              onChange={handleMdnChange}
              medium
              maxLength={8}
              disabled={freezeWindow}
              autoFocus
              onEnterPress={shouldSubmit ? () => { } : submitMdn}
            />
          </div>
          {error && <ErrorComponent />}
          {!freezeWindow &&
            <div className={captchaClass}>
              <ReCAPTCHA
                sitekey={Config.Captcha.SiteKey}
                onChange={verifyCaptcha}
                onExpired={() => setToken(null)}
                onErrored={() => setToken(null)}
              />
            </div>
          }
          <BaseButtonGroup>
            <BaseButton
              id={FIELD_IDS.SUBMIT_MDN}
              name="Submit"
              text={t("AskMdn.LetsBegin")}
              onClick={submitMdn}
              clicked={freezeWindow}
              disabled={process.env?.REACT_APP_ENV === 'QA' ? false : shouldSubmit}
              focused
            />
          </BaseButtonGroup>
        </BaseCard>
      </div>
      {showPopup && (
        <BasePopup
          show={true}
          title={<ModalTitle title={t("AskMdn.Popup.Title")} />}
          onClose={() => setShowPopup(false)}
        >
          <ul className="px-8 list-disc list-outside font-Lato-Regular text-default text-srgray-base leading-7">
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.1" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.2" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.3" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.4" />
            </li>
            <li className="my-4">
              <Trans i18nKey="AskMdn.Popup.5" />
            </li>
          </ul>
        </BasePopup>
      )}

    </>
  );
};

export default AskMdn;
