import { Trans, useTranslation } from "react-i18next";

import { TextMedium, TextRegular } from "../../../base/BaseText";

import { ReactComponent as YesIcon } from "../../../images/ic-round-yes.svg";
import { ReactComponent as NoIcon } from "../../../images/ic-round-no.svg";
import { ReactComponent as ActiveYesIcon } from "../../../images/ic-active-yes.svg";
import { ReactComponent as ActiveNoIcon } from "../../../images/ic-active-no.svg";
import { ReactComponent as DisabledYesIcon } from "../../../images/ic-disabled-yes.svg";
import { ReactComponent as DisabledNoIcon } from "../../../images/ic-disabled-no.svg";
import { ReactComponent as SubmittedYesIcon } from "../../../images/ic-submitted-disabled-yes.svg";
import { ReactComponent as SubmittedNoIcon } from "../../../images/ic-submitted-disabled-no.svg";
import { ReactComponent as Divider } from "../../../images/dashed-line.svg";
import classNames from "classnames";

const Buttons = ({ type, triage, disabled, onYesClick, onNoClick }) => {
  const getStatusByName = (name) => triage && triage[type].result === name;

  const isYesActive = getStatusByName("Yes");
  const isNoActive = getStatusByName("No");

  let yesButton = <YesIcon className="cursor-pointer" />;
  let noButton = <NoIcon className="cursor-pointer" />;

  if (disabled) {
    yesButton = isYesActive ? (
      <SubmittedYesIcon />
    ) : (
      <DisabledYesIcon />
    );
    noButton = isNoActive ? (
      <SubmittedNoIcon />
    ) : (
      <DisabledNoIcon />
    );
  }

  if (isYesActive && !disabled) yesButton = <ActiveYesIcon className="cursor-pointer" />;
  if (isNoActive && !disabled) noButton = <ActiveNoIcon className="cursor-pointer" />;

  const classes = classNames("w-full flex flex-row items-center justify-around", { "pointer-events-none" : disabled})
  return (
    <div className={classes}>
      <div className="w-full" onClick={onNoClick}>
        {noButton}
      </div>
      <div className="w-full" onClick={onYesClick}>
        {yesButton}
      </div>
    </div>
  );
};

const Damage = ({
  perilType,
  damageType,
  icon,
  title,
  triage,
  notes = [],
  link,
  disabled,
  handleYesClick,
  handleNoClick,
  handleLinkClick,
}) => {
  const linkClasses = classNames("w-5/6 text-left text-app-secondary text-default cursor-pointer", {
    "pointer-events-none": disabled,
  });

  const { t } = useTranslation();
  return (
    <div className="w-full space-y-6">
      <div className="w-full flex flex-row items-center text-2xl">
        <div className="w-1/6 self-start">{icon}</div>
        <div className="w-4/6 text-left">
          <Trans i18nKey={`Troubleshoot.${perilType}.${damageType}.Title`}>{title}</Trans>
        </div>
        <div className="w-2/6">
          <Buttons
            type={damageType}
            triage={triage}
            disabled={disabled}
            onYesClick={handleYesClick}
            onNoClick={handleNoClick}
          />
        </div>
      </div>
      {notes.length > 0 && (
        <div className="flex flex-row">
          <div className="w-1/6" />
          <div className="ml-8 w-5/6">
            <ul className="list-outside list-disc text-srgray-base leading-6 text-xl">
              {notes.map((note, idx) => {
                const text = t(`Troubleshoot.${perilType}.${damageType}.Damages.${idx + 1}`);
                const [normalText, largeText] = text.split("<br />");
                return (
                <li>
                  <TextRegular>{normalText}</TextRegular>
                  <br />
                  { largeText && <div className="text-2xl leading-8 -ml-10 text-left">{largeText}</div>}
                </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
      {link && (
        <div className="flex flex-row">
          <div className="w-1/6" />
          <div className={linkClasses} onClick={handleLinkClick}>
            <TextMedium>
              <Trans i18nKey={`Troubleshoot.${perilType}.${damageType}.Link`} />
            </TextMedium>
          </div>
        </div>
      )}
      <div>
        <Divider />
      </div>
    </div>
  );
};

export default Damage;
