import {
  callGetPciTokenApi,
  callGetServiceFeeApi,
  callProcessTransactionApi,
  callChargeOrderApi,
  callSubmitOrderApi,
} from "../reducers/paymentReducer";

export const getPciToken = (cacheId, amount) => ({
  type: callGetPciTokenApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/pcitoken",
    method: "POST",
    body: {
      SecurityTokenParameters: {
        SessionId: cacheId,
        AuthorizationAmount: amount ? parseFloat(amount) : 1
      },
    },
  },
});

export const getServiceFee = (CacheId, ServiceRequestId, Peril) => ({
  type: callGetServiceFeeApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/servicefee",
    method: "POST",
    body: {
      GetServiceFeeRequest: {
        SessionId: CacheId,
        ServiceRequestId: ServiceRequestId,
        MultipleFees: true,
        PerilType: Peril || undefined
      },
    },
  },
});

export const processTransaction = (
  pciToken,
  expiryMonth,
  expiryYear,
  firstName,
  lastName,
  postalCode,
  panData
) => ({
  type: callProcessTransactionApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/processtransaction",
    method: "POST",
    body: {
      ProcessTransactionRequest: {
        SecurityToken: pciToken,
        ExpMonth: expiryMonth,
        ExpYear: expiryYear,
        AppName: "iCareAPAC",
        FirstName: firstName,
        LastName: lastName,
        PanType: "CC",
        Zip: postalCode,
        PanData: panData,
      },
    },
  },
});

export const chargeOrder = (
  cacheId,
  serviceOrderId,
  serviceRequestId,
  addressId,
  cardType,
  pciToken,
  cvv,
  firstName,
  lastName,
  expiryYear,
  expiryMonth,
  postalCode,
  panData,
  referenceId,
  chargeOrder = null
) => ({
  type: callChargeOrderApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/chargeorder",
    method: "POST",
    body: {
      CreateChargeOrderParameters: {
        SessionId: cacheId,
        ServiceOrderId: serviceOrderId,
        ServiceRequestId: serviceRequestId,
        isSURClaim: true,
        ChargeOrder: chargeOrder ? chargeOrder : {
          PaymentMethodType: "CRE",
          ChargeOrderStatus: "PREAUTH",
          AddressId: addressId,
          AdditionalChargeAuth: "false",
          ChargeOrderCardBrand: cardType,
          ChargeOrderCardType: "CREDIT",
          PCIToken: pciToken,
          CardCheckNumber: cvv || 1,
          CardHolderFirstName: firstName,
          CardHolderLastName: lastName,
          ExpYear: expiryYear,
          ExpMonth: expiryMonth,
          ZipCode: postalCode,
          EncryptedPanData: panData,
          ReferenceId: referenceId,
          IsPromotionDiscount: false,
          ServiceOrderId: serviceOrderId,
        },
      },
    },
  },
});

export const chargeOrderForCod = (
  cacheId,
  serviceOrderId,
  serviceRequestId,
  addressId,
) => ({
  type: callChargeOrderApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/chargeorder",
    method: "POST",
    body: {
      CreateChargeOrderParameters: {
        SessionId: cacheId,
        ServiceOrderId: serviceOrderId,
        ServiceRequestId: serviceRequestId,
        ChargeOrder: {
          PaymentMethodType: "COD",
          ChargeOrderStatus: "PREAUTH",
          AddressId: addressId,
          AdditionalChargeAuth: "false",
          ServiceOrderId: serviceOrderId,
        },
      },
    },
  },
});

export const submitOrder = (cacheId) => ({
  type: callSubmitOrderApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: "/physicalassetfulfillment/v1/submitorder",
    method: "POST",
    body: {
      SubmitServiceOrderParameters: {
        SessionId: cacheId,
      },
    },
  },
});
