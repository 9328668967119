import React from "react";
import BaseIcon from "../../../base/BaseIcon/BaseIcon";
import { ReactComponent as AgentIcon } from "../../../images/icon-chat-agent.svg";

const AgentConnected = () => {
  return (
    <div className="w-full my-8">
      {/* <BaseIcon className="w-24 h-24" icon={agentIcon} name="Agent" center /> */}
      <AgentIcon className="app-BaseIcon-center w-24 h-24" />
      <div className="text-center text-default leading-7 text-srgray-primary">
        Agent has joined and
        <br /> will be ready to chat in a minute.
      </div>
    </div>
  );
};

export default AgentConnected;
