import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import { TextMedium, TextRegular } from "../../../base/BaseText";

import { isRegisteredDeviceConfirmed } from "../state/operators/deviceConfirmationOperators";
import { toPascalCase } from "../../../helpers/formatUtils";
import { ReactComponent as RegPhoneIcon } from "../../../images/image-phone-huawei.svg";

const ConfirmRegisteredDevice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetData = useSelector((state) => state.validation.verification.enrolledAsset);

  const registeredPhone = `${toPascalCase(assetData.Make.Name)} ${assetData.AssetCatalog.AssetCatalogName}`;
  const [disabled, setDisabled] = useState(false);
  const [isCorrectDevice, setIsCorrectDevice] = useState("");

  const handleSubmit = (isDeviceRegistered) => {
    setIsCorrectDevice(isDeviceRegistered ? "Yes" : "No");
    setDisabled(true);
    dispatch(isRegisteredDeviceConfirmed(isDeviceRegistered, assetData));
  };

  return (
    <BaseCard>
      <BaseLabel className="py-2">
        <Trans i18nKey="ConfirmRegisteredDevice.Title" components={{ medium: <TextMedium /> }} />
      </BaseLabel>
      <div className="mx-auto">
        <RegPhoneIcon />
      </div>
      <div className="text-1.6 text-center w-20r pt-8 mx-auto leading-7 text-srgray-base">
        <TextMedium>{registeredPhone}</TextMedium>
      </div>
      <div className="text-1.6 text-center w-full pb-4 leading-7 text-srgray-base">
        <Trans i18nKey="ConfirmRegisteredDevice.RegisteredDevice">
          <TextRegular>Registered Device</TextRegular>
        </Trans>
      </div>
      <BaseButtonGroup>
        <BaseButton
          text={t("YesButton")}
          onClick={() => handleSubmit(true)}
          disabled={disabled}
          clicked={isCorrectDevice === "Yes"}
          className="bg-app-nova border-app-nova"
          focused
        />
        <BaseButton
          text={t("NoButton")}
          onClick={() => handleSubmit(false)}
          disabled={disabled}
          clicked={isCorrectDevice === "No"}
          focused
        />
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRegisteredDevice;
