import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";

import BaseCard from "../../../base/BaseCard/BaseCard";
import BaseLabel from "../../../base/BaseLabel";
import { TextRegular } from "../../../base/BaseText";
import BaseButtonGroup from "../../../base/BaseButton/BaseButtonGroup";
import BaseButton from "../../../base/BaseButton/BaseButton";
import BaseTable, {
  BaseTR,
  BaseTD,
  BaseTH,
} from "../../../base/BaseTable/BaseTable";

import { ReactComponent as ConfirmIcon } from "../../../images/icon-confirm.svg";
import {
  confirmRequestDetailsSubmission,
  cancelRepairDetailsConfirmation,
} from "../state/operators/repairDetailsOperator";
import {
  EnumEnquiryModules,
  INCIDENT_BATTERY_AND_SCREEN,
  PERIL_TYPES,
} from "../../../components/EnquiryOptions/Constants";
import { getFormmatedDate } from "../../../helpers/dateUtils";
import { INCIDENT_PATH_TYPE } from "../../../helpers/constants";
import { isClaimInWarranty } from "../state/operators/contactsOperator";


/**
 * For Battery and screen replacement
 * We calculate fee using screen repair node + battery replacement node
 * 
 * For IW - battery and screen replacement
 * We calculate fee using screen repair IW node + battery replacement node
 */
 const calcFeeForBatAndScr = (state) => {
  const { BatteryReplacement, ScreenRepairWarranty } = state;
  const calcFeeNodes = [ScreenRepairWarranty, BatteryReplacement];
  
  const batScrReplacementFees = calcFeeNodes.reduce((acc, peril) => {
    const { FeeAmount, TaxAmount, TotalAmount, FeeType } = peril;
    const type = acc.FeeType ? `${acc.FeeType}-${FeeType}` : FeeType;
    const fee = (acc.FeeAmount ? parseFloat(acc.FeeAmount) + parseFloat(FeeAmount) : FeeAmount).toString();
    const tax = (acc.TaxAmount ? parseFloat(acc.TaxAmount) + parseFloat(TaxAmount) : TaxAmount).toString();
    const total = (acc.TotalAmount ? parseFloat(acc.TotalAmount) + parseFloat(TotalAmount) : TotalAmount).toString();
    acc = { ...peril, FeeType: type, FeeAmount: fee, TaxAmount: tax, TotalAmount: total };
    return acc;
  }, {})

  return batScrReplacementFees
}

const ConfirmRepairDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);

  const { caseType } = useSelector((state) => state.journeyMessages);
  const perilType = caseType && caseType.replace(/\s+/g, "");

  const incidentPathType = useSelector((state) => state.serviceRequest.type);
  const enquiryOption = useSelector(
    (state) => state.journeyMessages.enquiryOption
  );
  const determineDetails = useSelector(
    (state) => state.serviceRequest.determineIncidentDetails
  );
  const isNoActionRequired =
    incidentPathType === INCIDENT_PATH_TYPE.NO_ACTION_REQUIRED;
  let determinePickupSlot = "";
  if (isNoActionRequired) {
    const slotH = determineDetails.DeliveryInstructions.split("|")[0];
    determinePickupSlot =
      slotH === "16H"
        ? "12pm - 4pm"
        : slotH === "12H"
          ? "9am - 12pm"
          : slotH === "14H"
            ? determineDetails.ShippingMethodType === "Same Day"
              ? "12pm - 2pm"
              : "10am - 2pm"
            : "";
  }
  const scheduleData = useSelector((state) => state.claim.schedule);

  const pickupAddress = isNoActionRequired
    ? determineDetails.ShippingAddress
    : scheduleData.selectedPickupAddress;

  const pickupDate = isNoActionRequired
    ? determineDetails.ExpectedDeliveyDate
    : scheduleData.selectedPickupDate;

  const pickupSlot = isNoActionRequired
    ? determinePickupSlot
    : scheduleData.selectedPickupSlot;

  const customerCaseNumber = useSelector((state) =>
    enquiryOption === EnumEnquiryModules.CancelRequest
      ? state.serviceRequest.determineIncidentDetails.CustomerCaseNumber
      : state.serviceRequest.serviceRequestDetails.CustomerCaseNumber
  );

  const srFee = useSelector((state) =>{
    // If cancel request return fee from determine api response
    if(enquiryOption === EnumEnquiryModules.CancelRequest){
      return state.serviceRequest.determineIncidentDetails.ServiceFee
    }

    const isInWarranty = isClaimInWarranty(state);
    if(isInWarranty && caseType === INCIDENT_BATTERY_AND_SCREEN){
      const { TotalAmount } = calcFeeForBatAndScr(state.claim.payment.serviceFee)
      return TotalAmount
    } 

    return state.claim.payment.serviceFee[perilType].TotalAmountWithDiscount || state.claim.payment.serviceFee[perilType].TotalAmount
  });

  const getAddressText = (address) => {
    const { Address1, Address2, Address3, City, PostalCode } = address;
    return `${Address1} ${Address2} ${Address3} ${City} ${PostalCode}`;
  };

  const tableHeaders = [
    { key: "srNo", text: "Request No.", hide: false },
    { key: "srType", text: "Requested Service", hide: false },
    { key: "pickupAddress", text: "Pick-up Address", hide: false },
    { key: "returnAddress", text: "Return Address", hide: false },
    {
      key: "pickupDateTime",
      text: "Pick-up date / time",
      hide: false,
    },
    { key: "returnDateTime", text: "Return date / time", hide: false },
    { key: "srFee", text: "Fee", hide: false },
  ];

  const tableData = [
    {
      srNo: customerCaseNumber,
      srType: PERIL_TYPES[caseType],
      pickupAddress: getAddressText(pickupAddress),
      pickupDateTime: (
        <span>
          {`${getFormmatedDate(pickupDate, "d M yyyy")},`}
          <br />
          {`${getFormmatedDate(pickupDate, "D")}`}
          <br />
          {`${pickupSlot}`}
        </span>
      ),
      returnAddress: getAddressText(scheduleData.selectedDeliveryAddress),
      returnDateTime: (
        <span>
          {`${getFormmatedDate(
            scheduleData.selectedDeliveryDate,
            "d M yyyy"
          )},`}
          <br />
          {`${getFormmatedDate(scheduleData.selectedDeliveryDate, "D")}`}
          <br />
          {`${scheduleData.selectedDeliverySlot}`}
        </span>
      ),
      srFee: (
        <span>
          <strong>${srFee}</strong> (inclusive of GST)
        </span>
      ),
    },
  ];

  const handleSubmit = () => {
    setDisabled(true);
    dispatch(confirmRequestDetailsSubmission());
  };

  const handleCancelClick = () => {
    setDisabled(true);
    dispatch(cancelRepairDetailsConfirmation());
  };

  const newRequestTypes = [
    EnumEnquiryModules.NewRequest,
    EnumEnquiryModules.NewRequestBatteryReplacement,
    EnumEnquiryModules.NewRequestScreenAndBattery,
  ];

  const isNewRequest = newRequestTypes.includes(enquiryOption);
  let title = enquiryOption;
  let tableTitle = enquiryOption;
  let buttonTitle = enquiryOption;

  if (isNewRequest) {
    title = EnumEnquiryModules.NewRequest;
    tableTitle = EnumEnquiryModules.NewRequest;
    buttonTitle = EnumEnquiryModules.NewRequest;
  }

  return (
    <BaseCard>
      <ConfirmIcon className="app-BaseIcon-center" />
      <BaseLabel>
        <Trans i18nKey={`ConfirmRepairDetails.${title}.Title`}>
          <TextRegular>
            Please check and confirm your
            <br />
            request details below.
          </TextRegular>
        </Trans>
      </BaseLabel>
      <BaseTable
        title={t(`ConfirmRepairDetails.${tableTitle}.TableTitle`)}
        verticle
      >
        {tableHeaders.map((header) => {
          return (
            !header.hide && (
              <BaseTR key={header.key}>
                <BaseTH>{header.text}</BaseTH>
                {tableData.map((data, i) => (
                  <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
                ))}
              </BaseTR>
            )
          );
        })}
      </BaseTable>
      <BaseButtonGroup>
        <BaseButton
          className="border-app-nova bg-app-nova w-full px-16"
          text={t(`ConfirmRepairDetails.${buttonTitle}.Button`)}
          onClick={handleSubmit}
          disabled={disabled}
          clicked={disabled}
        />
        {[...newRequestTypes, EnumEnquiryModules.ResumeRequest].includes(
          enquiryOption
        ) && (
          <BaseButton
            className="w-full px-16"
            text={t("CancelButton")}
            onClick={handleCancelClick}
            disabled={disabled}
          />
        )}
      </BaseButtonGroup>
    </BaseCard>
  );
};

export default ConfirmRepairDetails;
