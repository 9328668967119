export const CHAT_STATUS = {
  INITIATED: "INITIATED",
  CONNECTED: "CONNECTED",
  ABANDONED: "ABANDONED",
  AGENT_DISCONNECT: "AGENT_DISCONNECT",
  USER_DISCONNECT: "USER_DISCONNECT",
  ENDED: "ENDED",
  TRANSFERRED: "TRANSFERRED",
  ACCEPTED: "ACCEPTED",
  AGENT_BUSY: "AGENT_BUSY",
};

export const AGENT_STATUS = {
  RESERVED: "reserved",
  ASSIGNED: "assigned",
  COMPLETED: "completed",
  CANCELED: "canceled"
};
