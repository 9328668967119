import {
  addJourneyMessages,
  sleep,
} from "../../../../helpers/addJourneyMessages";
import createMessage from "../../../../helpers/createMessage";
import {
  DEVICE,
  API_PROGRESS,
  COMPONENTS,
  PROGRESS_DATA,
  ACTIVITY,
  ONLINE_JOURNEY_STATUS_LIST,
} from "../../../../helpers/constants";
import { setDeviceMakeAndModel, verifyImeiApi } from "../actions/actions";
import {
  updateApiProgress,
  clearApiProgress,
  updateFlowProgress,
  endFlow,
  serviceUnavailable,
} from "../../../../actions/flowWindow";
import { getMakeModelList } from "../../../../actions/cowrapperapi";
import {
  saveChosenDeviceImei,
  saveDeviceMakeOptions,
  saveDeviceModelOptions,
  saveResolvedImeiByChat,
} from "../reducers/deviceConfirmationReducer";
import { udpateChatInputVisibility } from "../../../Appsync/state/operators";
import { setChatReason } from "../../../Appsync/state/reducers";
import { createServiceRequest } from "../../../../actions/serviceRequestApi";
import { EnumEnquiryModules, PROGRAM_NAME } from "../../../../components/EnquiryOptions/Constants";
import updateVisitor from "../../../Appsync/updateVisitor";
import { confirmToCancelRequest } from "./cancelRequestOperator";
import { getMaskedData, isEmpty } from "../../../../helpers/formatUtils";

export const proceedForDeviceConfirmation = (text) => (dispatch, getStore) => {
  const { replacedAsset } = getStore().validation.verification;

  let showComponent = isEmpty(replacedAsset)
    ? "confirmDevice"
    : "displayDevices";

  if (showComponent) {
    const initial = getStore().journeyMessages.flowProgress.percentage;
    dispatch(
      updateFlowProgress(
        PROGRESS_DATA.CONFIRM_DEVICE.title,
        PROGRESS_DATA.CONFIRM_DEVICE.count,
        initial
      )
    );
  }

  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
      createMessage("DEVICE_CONFIRMATION", "system", { showComponent }),
    ])
  );
};

export const isDeviceMakeAccepted = (deviceMake) => {
  return (
    deviceMake.toUpperCase() === DEVICE.APPLE ||
    deviceMake.toUpperCase() === DEVICE.SAMSUNG
  );
};

export const isDeviceSS3Supported = (deviceMake, program) => {
  if ((deviceMake.toUpperCase() === DEVICE.HONOR || deviceMake.toUpperCase() === DEVICE.NOTHING) &&
    program === PROGRAM_NAME) {
      return false;
  }
  return true;
};

export const isDeviceMakeXiaomi = (deviceMake) => {
  return false;
  // return (
  //   deviceMake.toUpperCase() === DEVICE.XIAOMI
  // );
};

export const isRegisteredDeviceConfirmed = (
  isDeviceRegistered,
  deviceData,
  fromDeviceList = false
) => async (dispatch, getStore) => {
  // const { enrolledAsset } = getStore().validation.verification;
  const { Name: deviceMake } = deviceData.Make;
  const { ClientOffer: programName } = getStore().validation?.agreement;
  let decision;
  let showComponent = "deviceNotEligible";
  const isAppleSamsung = isDeviceMakeAccepted(deviceMake);
  if (isDeviceRegistered) {
    decision = fromDeviceList
      ? `${deviceData.AssetInstance} ${deviceData.Make.Name} ${deviceData.AssetCatalog.AssetCatalogName}`
      : "Yes";

    if(programName === PROGRAM_NAME || isAppleSamsung){
      showComponent = "verifyImei";
    }

    if (isDeviceMakeXiaomi(deviceMake)) {
      showComponent = "deviceNotEligible";
    }

    if (!isDeviceSS3Supported(deviceMake, programName)) {
      showComponent = "deviceNotEligible";
    }

    dispatch(setDeviceMakeAndModel(deviceData, isAppleSamsung));
    dispatch(updateVisitor({ lastActivity: ACTIVITY.CONFIRM_DEVICE }));
  } else {
    decision = "No";
    showComponent = "chooseEnrolledDevice";
  }

  dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));

  if (showComponent === "verifyImei") {
    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.VERIFY_IMEI.title,
        PROGRESS_DATA.VERIFY_IMEI.count,
        initial
      )
    );
    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.NewRequestBatteryReplacement,
        EnumEnquiryModules.NewRequestScreenAndBattery,
        EnumEnquiryModules.InWarrantyRequest
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR,
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(createSRResponse)) return;

      // After API call
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR_SUCCESS,
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }
  }

  dispatch(
    addJourneyMessages([
      createMessage("DEVICE_CONFIRMATION", "system", {
        showComponent,
      }),
    ])
  );
};

export const submitChosenEnrolledDevice = (deviceData) => async (
  dispatch,
  getStore
) => {
  let decision = "cancel";
  let data;
  let module;

  const { ClientOffer: programName } = getStore().validation?.agreement;

  if (deviceData.imei) {
    decision = `${deviceData.make.Make} ${deviceData.model.PopularName}`;

    dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));

    const isAppleSamsung = isDeviceMakeAccepted(deviceData.make.Make);
    const isAllowed = (programName === PROGRAM_NAME && isDeviceSS3Supported(deviceData.make.Make, programName)) || isAppleSamsung;
    const showComponent = isAllowed
      ? "warrantyConfirmation"
      : "deviceNotEligible";

    if (isDeviceMakeXiaomi(deviceData.make.Make)) {
      showComponent = "deviceNotEligible";
    }

    const {
      flowProgress: { percentage: initial },
      enquiryOption,
    } = getStore().journeyMessages;
    const { CacheId } = getStore().session.sessionData.Configurations;

    if (
      [
        EnumEnquiryModules.NewRequest,
        EnumEnquiryModules.InWarrantyRequest,
        EnumEnquiryModules.NewRequestBatteryReplacement,
        EnumEnquiryModules.NewRequestScreenAndBattery,
      ].includes(enquiryOption)
    ) {
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR,
          40,
          COMPONENTS.CONFIRM_DEVICE,
          0
        )
      );

      const createSRResponse = await dispatch(
        createServiceRequest(CacheId)
      ).catch((err) => dispatch(serviceUnavailable()));
      if (isEmpty(createSRResponse)) return;

      // After API call
      await dispatch(
        updateApiProgress(
          API_PROGRESS.CREATE_SR_SUCCESS,
          100,
          COMPONENTS.CONFIRM_DEVICE,
          40
        )
      );
      await sleep(1000);
      await dispatch(clearApiProgress());

      dispatch(
        updateVisitor({
          lastActivity: ACTIVITY.NEW_REQUEST,
          journeyStatus: ONLINE_JOURNEY_STATUS_LIST.INPROGRESS,
          caseNumber:
            createSRResponse.ServiceRequestDetails.CreateServiceRequestResults
              .CustomerCaseNumber,
        })
      );
    }
    
    if (isAllowed) {
      module = "DEVICE_CONFIRMATION";
      data = { showComponent };

      const deviceDetails = {
        AssetCatalog: {
          AssetCatalogId: deviceData.model.CatalogId,
          AssetCatalogName: deviceData.model.AssetCatalogName,
        },
        Make: {
          MakeId: deviceData.make.MakeId,
          Name: deviceData.make.Make,
        },
        Model: {
          ModelId: deviceData.model.ModelId,
          Name: deviceData.model.Model,
        },
        IMEI: deviceData.imei,
      };
      dispatch(setDeviceMakeAndModel(deviceDetails, isAppleSamsung));
      dispatch(setImeiForChosenDevice(deviceData.imei));

      await dispatch(
        updateFlowProgress(
          PROGRESS_DATA.TROUBLESHOOT.title,
          PROGRESS_DATA.TROUBLESHOOT.count,
          initial
        )
      );

      await dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.AnswerFollowingQuestion",
          }),
          createMessage("TEXT", "system", {
            key: "SystemMessage.DeviceWillBeDiagnosed",
          }),
        ])
      );
    } else {
      // device in-eligible
      module = "DEVICE_CONFIRMATION";
      data = { showComponent };
      dispatch(confirmToCancelRequest("", "device-ineligible"));
    }
  } else {
    dispatch(addJourneyMessages([createMessage("TEXT", "user", decision)]));
    module = "ENQUIRY_OPTIONS";
    data = {};
  }

  dispatch(addJourneyMessages([createMessage(module, "system", data)]));
};

export const verifyDeviceImei = (imei) => async (dispatch, getStore) => {
  // Before API call
  const { CacheId } = getStore().session.sessionData.Configurations;

  const {
    imeiVerification,
    resolvedImeiByChat,
    selectedAsset: { AssetInstance: assetInstance },
  } = getStore().claim.deviceConfirmation;
  const imeiVerificationFailedAttempt = imeiVerification
    ? imeiVerification.IMEIVerificationFailedAttempt
    : 0;
  const maskedImei = getMaskedData(imei, "IMEI");
  await dispatch(
    addJourneyMessages([createMessage("TEXT", "user", maskedImei)])
  );
  await dispatch(
    updateApiProgress(API_PROGRESS.VERIFY_IMEI, 40, COMPONENTS.VERIFY_IMEI, 0)
  );

  // API call
  const response = await dispatch(
    verifyImeiApi({
      imei,
      cacheId: CacheId,
      assetInstance,
      imeiVerificationFailedAttempt,
    })
  ).catch((err) => dispatch(serviceUnavailable()));
  if (isEmpty(response)) return;

  const {
    VerificationOutcome,
    IMEIVerificationFailedAttempt,
  } = response.VerifyIMEIResults;

  // After API call
  await dispatch(
    updateApiProgress(
      API_PROGRESS.VERIFY_IMEI_SUCCESS,
      100,
      COMPONENTS.VERIFY_IMEI,
      40
    )
  );
  await sleep(1000);
  await dispatch(clearApiProgress());
  if (VerificationOutcome) {

    dispatch(updateVisitor({ lastActivity: ACTIVITY.VERIFY_IMEI }));
    const initial = getStore().journeyMessages.flowProgress.percentage;
    await dispatch(
      updateFlowProgress(
        PROGRESS_DATA.TROUBLESHOOT.title,
        PROGRESS_DATA.TROUBLESHOOT.count,
        initial
      )
    );

    let showModule = "DEVICE_CONFIRMATION";
    let showComponent = "warrantyConfirmation";

    dispatch(
      addJourneyMessages([
        createMessage(showModule, "system", { showComponent }),
      ])
    );

  } else {
    if (IMEIVerificationFailedAttempt < 3) {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImei",
          }),
          createMessage("DEVICE_CONFIRMATION", "system", {
            showComponent: "verifyImei",
          }),
        ])
      );
    } else {
      dispatch(
        addJourneyMessages([
          createMessage("TEXT", "system", {
            key: "SystemMessage.IncorrectImeiTerminate",
          }),
        ])
      );
      if (!resolvedImeiByChat) {
        await dispatch(setChatReason("Invalid Imei"));
        dispatch(udpateChatInputVisibility(true));
      }
    }
  }
};

export const getDeviceMakeModelOptions = (makeId) => async (
  dispatch,
  getStore
) => {
  const { ClientId } = getStore().session.sessionData.Configurations;
  if (makeId) {
    const models = await dispatch(getMakeModelList(ClientId, makeId));
    await dispatch(saveDeviceModelOptions(models));
  } else {
    const makes = await dispatch(getMakeModelList(ClientId));
    await dispatch(saveDeviceMakeOptions(makes));
  }
  return;
};

export const handleDeviceHoldClose = (text) => async (dispatch) => {
  dispatch(addJourneyMessages([createMessage("TEXT", "user", text)]));
  dispatch(endFlow());
};

export const initiateChatForImei = () => async (dispatch) => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", {
        key: "VerifyImei.DontKnowLink",
      }),
    ])
  );
  await dispatch(setChatReason("I Don't Know My IMEI"));
  dispatch(udpateChatInputVisibility(true));
};

export const warrantyConfirmation = (status) => async (dispatch, getStore) => {

  await dispatch(
    addJourneyMessages([createMessage("TEXT", "user", status)])
  );

  const isWarrantyConfirmed = status === "Yes";

  const { caseType, enquiryOption } = getStore().journeyMessages;
  const inWarranty = enquiryOption === "In-WarrantyRequest";

  let perilType = caseType && caseType.replace(/\s+/g, "");
  if(inWarranty){
    perilType = `InWarranty${perilType}`
  }

  if (isWarrantyConfirmed) {
    dispatch(
      addJourneyMessages([
        createMessage("TEXT", "system", {
          key: "SystemMessage.PerformEligibilityCheck1",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.PerformEligibilityCheck2",
        }),
        createMessage("TEXT", "system", {
          key: "SystemMessage.PerformEligibilityCheck3",
        }),
        createMessage("TROUBLESHOOT", "system", {
          showComponent: "Troubleshoot",
          perilType,
          inWarranty
        }),
      ])
    );
  } else {
    dispatch(confirmToCancelRequest("", "declined warranty confirmation", true));
  }

}

export const setImeiResolvedByChat = () => async (dispatch) => {
  dispatch({
    type: saveResolvedImeiByChat.toString(),
    payload: true,
  });
};

export const setImeiForChosenDevice = (imei) => async (dispatch) => {
  dispatch({
    type: saveChosenDeviceImei.toString(),
    payload: imei,
  });
};

export const handleWhyDeviceNotEligible = (text) => async dispatch => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
      createMessage("DEVICE_CONFIRMATION", "system", { showComponent: "whyDeviceNotEligible" }),
    ])
  )
}

export const handleBackToMainMenu = (text) => dispatch => {
  dispatch(
    addJourneyMessages([
      createMessage("TEXT", "user", text),
      createMessage("ENQUIRY_OPTIONS", "system", {})
    ])
  )
}