import { createSlice } from "@reduxjs/toolkit";
import { INCIDENT_BATTERY_REPLACEMENT, INCIDENT_SCREEN_REPAIR, INCIDENT_SCREEN_REPAIR_WARRANTY } from "../../../../components/EnquiryOptions/Constants";

let initialState = {
  resolvedByChat: false,
};

const setServiceFeeByPeril = (serviceFees = []) => {

  const ScreenRepair = {...(serviceFees.find(sf => sf.IncidentType === INCIDENT_SCREEN_REPAIR.toUpperCase())?.Fees), 
    ...{TotalAmountWithDiscount: (serviceFees.find(sf => sf.IncidentType === INCIDENT_SCREEN_REPAIR.toUpperCase())?.TotalAmountWithDiscount)}};

  const ScreenRepairWarranty = serviceFees.find(sf => sf.IncidentType === INCIDENT_SCREEN_REPAIR_WARRANTY.toUpperCase())?.Fees;
  
  const BatteryReplacement = serviceFees.find(sf => sf.IncidentType === INCIDENT_BATTERY_REPLACEMENT.toUpperCase())?.Fees;

  const BatScrReplacement = [ScreenRepair, BatteryReplacement].reduce((acc, peril) => {
    const { FeeAmount, TaxAmount, TotalAmount, FeeType, TotalAmountWithDiscount } = peril;
    const type = acc.FeeType ? `${acc.FeeType}-${FeeType}` : FeeType;
    const fee = (acc.FeeAmount ? parseFloat(acc.FeeAmount) + parseFloat(FeeAmount) : FeeAmount).toString();
    const tax = (acc.TaxAmount ? parseFloat(acc.TaxAmount) + parseFloat(TaxAmount) : TaxAmount).toString();
    const total = (acc.TotalAmount ? parseFloat(acc.TotalAmount) + parseFloat(TotalAmount) : TotalAmount).toString();
    
    const totalWithDiscount = (acc.TotalAmountWithDiscount ? parseFloat(acc.TotalAmountWithDiscount) + parseFloat(TotalAmount) : TotalAmountWithDiscount || TotalAmount).toString();
    
    acc = { ...peril, FeeType: type, FeeAmount: fee, TaxAmount: tax, TotalAmount: total, TotalAmountWithDiscount: totalWithDiscount };
    
    console.log("acc", acc)
    return acc;
  }, {})

  return {
    ScreenRepair,
    ScreenRepairWarranty,
    BatteryReplacement,
    BatScrReplacement
  }
}


const paymentReducer = createSlice({
  name: "payment",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    setCCDetails(state, action) {
      state.ccDetails = action.payload;
    },
    setPaymentData(state, action) {
      state.isCardValid = action.payload;
    },
    setPaymentVerificationAttempt(state, action) {
      state.paymentVerificationAttempt = action.payload;
    },
    callGetServiceFeeApi(state, action) {
      // state.serviceFee = {};
    },
    callGetServiceFeeApiSuccess(state, action) {
      /**
       * This API is required in case of resume
       * Since enrolled service fee API is not called in resume case
       * So we need to map all perils with their appropriate fees
       */
      state.serviceFee = setServiceFeeByPeril(action.payload.GetServiceFeeResponse?.ServiceFee?.ServiceFee)
    },
    callGetServiceFeeApiFailure(state, action) {
      state.error = action.payload;
    },
    callGetPciTokenApi(state, action) {
      state.pciToken = {};
    },
    callGetPciTokenApiSuccess(state, action) {
      state.pciToken = action.payload;
    },
    callGetPciTokenApiFailure(state, action) {
      state.error = action.payload;
    },
    callProcessTransactionApi(state, action) {
      state.processTransaction = {};
    },
    callProcessTransactionApiSuccess(state, action) {
      state.processTransaction = action.payload;
    },
    callProcessTransactionApiFailure(state, action) {
      state.error = action.payload;
    },
    callChargeOrderApi(state, action) {
      state.chargeOrder = {};
    },
    callChargeOrderApiSuccess(state, action) {
      state.chargeOrder = action.payload;
    },
    callChargeOrderApiFailure(state, action) {
      state.error = action.payload;
    },
    callSubmitOrderApi(state, action) {
      state.submitOrder = {};
    },
    callSubmitOrderApiSuccess(state, action) {
      state.submitOrder = action.payload;
    },
    callSubmitOrderApiFailure(state, action) {
      state.error = action.payload;
    },
    setPaymentResolvedByChat(state, action) {
      state.resolvedByChat = action.payload;
    },
    callGetEnrolledServiceFeeApi(state, action) {
      state.serviceFee = {};
    },
    callGetEnrolledServiceFeeApiSuccess(state, action) {
      state.serviceFee = setServiceFeeByPeril(action.payload.GetServiceFeeResponse.ServiceFees)
    },
    callGetEnrolledServiceFeeApiFailure(state, action) {
      state.error = action.payload;
    }
  },
});

const { actions, reducer } = paymentReducer;
export const {
  resetStore,
  setPaymentData,
  setPaymentVerificationAttempt,
  callGetServiceFeeApi,
  callGetPciTokenApi,
  setCCDetails,
  callProcessTransactionApi,
  callChargeOrderApi,
  callSubmitOrderApi,
  setPaymentResolvedByChat,
  callGetEnrolledServiceFeeApi,
} = actions;
export default reducer;
